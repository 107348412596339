import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_ADD_USER,
    REQUEST_USERS,
    SET_USERS,
    REQUEST_EDIT_USER,
    SET_EDIT_USER,
    SET_ERROR_MSG,
    SET_ADD_USER,
    SET_RESAT_USER_PASS,
    REQUEST_RESAT_USER_PASS,
    SET_SEND_PASS_BY_EMAIL,
    REQUEST_SEND_PASS_BY_EMAIL,
    REQUEST_SEND_SIGN_IN_INSTRUCTIONS,
    SET_SEND_SIGN_IN_INSTRUCTIONS
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";
import {cache, loadImages} from "../../utils/SuspenseImage";

function* fetchUsers() {
    let users = yield call(GetResource, `/User?IncludeRoles=true&IncludeApplications=true`);
    yield call(loadImages, users.data);
    yield put({ type: SET_USERS, users: users.data});
}

function* addUser({data}) {
    const user = yield call(PostResource, `/User`, {...data});
    yield put({type: REQUEST_USERS});
    yield put({type: SET_ADD_USER, user});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* resetUserPass({data}) {
    const res = yield call(PostResource, `/User/reset-password`, data);
    yield put({type: SET_RESAT_USER_PASS, data: res});
    // yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* sendPassByEmail({data}) {
    const res = yield call(PostResource, `/User/notify-new-user`, data);
    yield put({type: SET_SEND_PASS_BY_EMAIL, data: res});
    // yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* sendSignInInstructions({data}) {
    const res = yield call(PostResource, `/User/notify-new-user`, data);
    yield put({type: SET_SEND_SIGN_IN_INSTRUCTIONS, data: res});
    // yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* editUser({data}) {
    const user =  yield call(PutResource, `/user/${data.id}`, data);
    yield put({type: SET_EDIT_USER, data: user.data});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}


function* usersSaga() {
    yield takeLatest(REQUEST_USERS,safe(onError, fetchUsers));
    yield takeLatest(REQUEST_ADD_USER, safe(onError, addUser));
    yield takeLatest(REQUEST_EDIT_USER, safe(onError, editUser));
    yield takeLatest(REQUEST_RESAT_USER_PASS, safe(onError, resetUserPass));
    yield takeLatest(REQUEST_SEND_SIGN_IN_INSTRUCTIONS, safe(onError, sendSignInInstructions));
    yield takeLatest(REQUEST_SEND_PASS_BY_EMAIL, safe(onError, sendPassByEmail)); 
}

export default usersSaga;