
import {
    SET_CHANGE_PASSWORD
} from "@actions/actionTypes";

export default function passwordHasBeenChangedReducer(state = false, action) {
    switch (action.type) {
        case SET_CHANGE_PASSWORD:
            return action.passwordHasBeenChanged
        default:
            return state;
    }
}
