import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    resetPassword
} from "@actions/usersActions";
import {
    REQUEST_RESAT_USER_PASS
} from "@actions/actionTypes";

import {Modal, Form, Input, Radio, Checkbox, Space, Button} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import UserEmptyImg from "@assets/img/userEmptyImg.svg";
import ValidPasswordPopup from "@components/validPasswordPopup";


function ResetPasswordForPopup({show=true, user, intl, handleCancel, onSuccess, loading, resetPassword}) {
    const [form] = Form.useForm();
    const [isShowPassInput, setIsShowPassInput] = useState(false)
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [formValidation, setFormValidation] = useState(false);
    const [isFocusOnPassword, setIsFocusOnPassword] = useState(false);
    const [passwords, setPassword] = useState({password: ''});
    const [validatePassInputs, setValidatePassInputs] = useState([true]);
    const [isValidForm, setIsValidForm] = useState(true);

    useEffect(() => {
        subscribeForError(REQUEST_RESAT_USER_PASS, () => {
            onSuccess();
        });
        form.setFieldsValue({generatePassword: 0})
        setIsShowPassInput(false);
        return () => {
            unSubscribeForError(REQUEST_RESAT_USER_PASS);
        }
    }, [])

    const changeInput = (e) => {
        setPassword({...passwords, [e.target.name]: e.target.value || ''});
    }

    useEffect(() => {
        setIsValidForm(validatePassInputs[0]);
    }, [validatePassInputs, passwords])

    const setIsValid = (data) => {
        setValidatePassInputs(prev => {
            let newState = [...prev];
            newState[data.index] = data.isValid;
            return newState;
        })
    }

    const onFinish = (data) => {
        let sendData = {
            generatePassword: data.generatePassword == 0? true : false,
            email: user.email
        };
        if (data.generatePassword == 1) {
            sendData.password = data.password;
        }
        resetPassword(sendData);
    }

    const changeFormValue = (data) => {
        formValueChange()
        if (data.hasOwnProperty('generatePassword')) {
            setIsShowPassInput(!!data.generatePassword)
        }
    }

    const formValueChange = () => {
        setFormValidation(false);
        form.getFieldsError().some((field) => {
            if(field.errors.length > 0) {
                setFormValidation(true)
            }

        })
    }

    return (
        <>
            {
                <Modal
                    title={<FormattedMessage id='reset_password_for'/>}
                    centered
                    className={classname(`formModal reset-pass-for-modal`,{isShowPassInput: isShowPassInput})}
                    visible={show}
                    width={440}
                    onCancel={() => handleCancel()}
                    footer={false}>
                        <div className="user-info">
                            <img src={user.imageUrl || UserEmptyImg} />
                            <div className="detals">
                                <h4>{user.firstName} {user.lastName}</h4>
                                <h5>{user.email}</h5>
                            </div>
                        </div>
                    <Form
                        layout="vertical"
                        form={form}
                        name="reset-pass-for-form"
                        onValuesChange={changeFormValue}
                        onFinish={onFinish}
                        requiredMark={isShowPassInput}
                        >
                        <Form.Item noStyle name="generatePassword">
                            <Radio.Group className="reset-pass-radio-group">
                                <Space direction="vertical" size={[0, 24]}>
                                    <Radio value={0}>
                                        <Space direction="vertical" size={[0, 8]}>
                                            <span className="lable">
                                                <FormattedMessage id='automatically_generate_a_password'/>
                                            </span>
                                            <span className="description" >
                                                <FormattedMessage id='automatically_generate_a_password_desc'/>
                                            </span>
                                        </Space>
                                    </Radio>
                                    <Radio value={1}>
                                        <Space direction="vertical">
                                            <span className="lable">
                                                <FormattedMessage id='create_password'/>
                                            </span>
                                        </Space>
                                    </Radio>
                                </Space>
                            </Radio.Group>
                        </Form.Item>
                        <div style={{display: ((isShowPassInput && 'block') || "none")}}>
                            <ValidPasswordPopup open={isFocusOnPassword} inputText={passwords.password} index={0} onValid={setIsValid}>
                                <Form.Item label={<FormattedMessage id='new_password'/>}
                                            className="with-icon"
                                            style={{marginTop: 20}}
                                            onBlur={() => requestAnimationFrame(formValueChange)}
                                            name="password">
                                    <Input.Password onChange={changeInput}
                                                    onFocus={() => setIsFocusOnPassword(true)} 
                                                    onBlur={() => setIsFocusOnPassword(false)}
                                                    autoComplete="off"
                                                    name="password"
                                                    placeholder={intl.formatMessage({id: "type_password"})}/>
                                </Form.Item>
                            </ValidPasswordPopup>
                        </div>
                        
                        <Form.Item className="MainModal--footer small-footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    onClick={() => requestAnimationFrame(formValueChange)}
                                    className={classname("miniBtn", {submit: (formValidation || !isValidForm) && isShowPassInput})}
                                    loading={loading._resat_user_pass}
                                    disabled={(formValidation || !isValidForm) && isShowPassInput}
                            >
                                <FormattedMessage id='reset'/>
                            </Button>
                            <div className="error_box"><FormattedMessage id={"form_error"}/></div>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        appTreeData: state.appTreeData
    }
}

const mapDispatchToProps = {
    resetPassword
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForPopup));