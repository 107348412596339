export const cache = {
  __cache: {},
  read(src) {
    if (!src) {
      return;
    }

    if (!this.__cache[src]) {
      this.__cache[src] = new Promise((resolve) => {
        const img = new Image();
        img.onload = () => {
          resolve(img);
        };
        img.src = src;
        // setTimeout(() => resolve({}), 7000);
      });
    }
    return this.__cache[src];
  },
  clearImg: (src) => {
    delete this.__cache[src];
  }
};
const _cache = {};

export const loadImages = async (arr) => {
  const p =  arr.map(item => {
      return new Promise((resolve, reject) => {
        if (!item.imageUrl) return resolve('');
        if (_cache[item.imageUrl]) return resolve(_cache[item.imageUrl]);
          const img = new Image();

          img.src = item.imageUrl;
          img.onload = () => {
            _cache[item.imageUrl] = img;
              resolve(img);
          };
          img.onerror = (e) => {
            console.error(e)
            resolve('');
          }
      });
  });

  return await Promise.all(p);
}
