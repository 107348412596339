import React from 'react';
import {connect} from "react-redux";
import { Row, Col } from 'antd';
import {FormattedMessage} from "react-intl";
import {
    Login,
    CallbackLogin
} from './login';
import CallbackLogout from "./Logout/CallbackLogout";
import ForgotPassword from './forgotPassword';
import { Switch, Route, Redirect } from 'react-router-dom';
import NewPassword from './newPassword';
import ChangePassword from './changePassword';
import PasswordChanged from './passwordChanged';
// import logo from "@assets/img/binaryoptionslogo.svg";
import classnames from "classnames";
import LoadingPage from './LoadingPage';
import './style.sass';

function LoginPage({isEndSilentRequest}) {

    return (
        <>
            <Row className={classnames("login-block ", {'loading-silent': !isEndSilentRequest})}>
                <Col lg={24} >
                    <Switch>
                        <Route path="/callback/login">
                            <CallbackLogin />
                        </Route>
                        <Route path="/callback/logout">
                            <CallbackLogout />
                        </Route>
                        <Route path="/login">
                            <Login />
                        </Route>
                        <Route path="/forgot-password">
                            <ForgotPassword />
                        </Route>
                        <Route path="/change-password">
                            <ChangePassword />
                        </Route>
                        <Route path="/password-changed">
                            <PasswordChanged />
                        </Route>
                        <Route path="/" render={() => <Redirect to="/login"/>}/>
                        <Route exact path="/:all" render={() => <Redirect to='/login' />}/>
                    </Switch>
                </Col>
            </Row> 
            {(!isEndSilentRequest) && <LoadingPage></LoadingPage>}
        </>
    )
}

function mapStateToProps(state) {
    return {
        isEndSilentRequest: state.isEndSilentRequest
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);