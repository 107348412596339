import initialStore from "../initialStore";
import {
    CLEAR_ROLES,
    SET_ROLES,
    SET_EDIT_ROLE,
    SET_ERROR_MSG
} from "@actions/actionTypes";

export default function rolesReducer(state = initialStore.roles, action) {
    switch (action.type) {
        case SET_ROLES:
           return action.roles
        case SET_EDIT_ROLE:
            return state.map(app => {
                if (app.id === action.data.id) {
                    return action.data
                } else {
                    return app;
                }
            })
        case CLEAR_ROLES:
           return initialStore.roles
        default:
            return state;
    }
}
