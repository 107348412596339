import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    addUser,
    editUser
} from "@actions/usersActions";
import {
    REQUEST_ADD_USER,
    REQUEST_EDIT_USER
} from "@actions/actionTypes";
import {
    getRoles,
    clearRoles
} from '@actions/rolesActions';

import {Modal, Form, Button, Radio, Select, Tooltip, Input} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import {UsersStatus, UsersStatusType} from "../../constants/Enums";
import { MinusOutlined, PlusOutlined, DeleteFilled} from '@ant-design/icons';
import ImgUpload from "@components/ImgUpload/ImgUpload";
// import Input from "../InputsValidationDecorator";

function UserControlPopup({handleCancel, show, intl, user, loading, roles, addUser, editUser, getRoles, clearRoles}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [statusSelect, setStatusSelect] = useState(0);
    const [fildeError, setFildeError] = useState(false);
    const [resetRolesBtn, setResetRolesBtn] = useState(false)

    const chackIsValid = () => {
        setTimeout(() => {
            let isError = false;
            setFildeError(false);
            form.getFieldsError().some((field) => {
                isError = isError || field.errors.length > 0;
            })
            setFildeError(isError);
        }, 100)
    }

    const formChange = (d) => {
        if(d['status'] !== undefined) {
            setStatusSelect(d['status']);
        }
        chackIsValid();
    }

    useEffect(() => {
        getRoles();
        if (user.id) {
            subscribeForError(REQUEST_EDIT_USER, () => {
                handleCancel();
            }, user.id);
        } else {
            subscribeForError(REQUEST_ADD_USER, () => {
                handleCancel();
            });
        }
        form.setFieldsValue({status: 0, roles: []})
        return () => {
            clearRoles();
            unSubscribeForError(REQUEST_ADD_USER);
            unSubscribeForError(REQUEST_EDIT_USER);
        }
    }, [])

    useEffect(() => {
        if(user.id) {
            const u = Object.assign({}, user);
            u.roles = [];
            form.setFieldsValue(u);
        }
    }, [user])

    useEffect(() => {
        if (user.id && roles.length > 0) {
            roles = user.roles.map(role => role.id);
            checkRolesResetBtn(roles)
            form.setFieldsValue({roles});
        }
    }, [roles])

    const onFinish = (data) => {
        // console.log(data)
        if (show === "add") {
            addUser(data)
        } else {
            editUser({...data, id: user.id, email: user.email})
        }
    }

    const checkRolesResetBtn = (val = undefined) => {
        const roles = val || form.getFieldValue("roles")
        if (roles && roles.length) {
            setResetRolesBtn(true)
        } else {
            setResetRolesBtn(false)
        }
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_user'/> :
                        <FormattedMessage id='edit_user'/>}
                    centered
                    className={`formModal user-modal`}
                    visible={show}
                    width={440}
                    onCancel={() => handleCancel()}
                    
                    footer={false}>
                    <Form
                        layout="vertical"
                        form={form}
                        name="user-form"
                        onFinish={onFinish}
                        onValuesChange={formChange}
                        scrollToFirstError
                        onFinishFailed={() => chackIsValid()}
                        validateTrigger="onBlur"
                        >
                        <div className="horizontal">
                            <Form.Item name="imageUrl"
                                        label={false}
                                        onBlur={() => chackIsValid()}
                                        // rules={[
                                        //     {
                                        //         required: true,
                                        //         message: <FormattedMessage id='icon_validator'/>
                                        //     },
                                        // ]}
                                        className="userImgUpload"
                                        >
                                <ImgUpload  classNameByIcon={UsersStatus[statusSelect]}
                                            icon={<PlusOutlined />}
                                            isOnlyImg={true}
                                            uploadText={<FormattedMessage id='upload_avatar'/>} />
                            </Form.Item>

                            <Form.Item 
                                rules={[
                                    {
                                        required: true,
                                        message: <FormattedMessage id='status_validator'/>
                                    }
                                ]}
                                onBlur={() => chackIsValid()}
                                name="status" >
                                <Radio.Group>
                                    {
                                        UsersStatus.map((status, i) => (
                                            <Radio value={UsersStatusType[status]} className={status} key={UsersStatusType[status]}>
                                                <FormattedMessage id={status}/>
                                            </Radio>
                                        ))
                                    }
                                </Radio.Group>
                            </Form.Item>
                        </div>
                        <div className="horizontal name-lastname">
                            <Form.Item label={<FormattedMessage id='user_name'/>}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage id='name_validator'/>
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {

                                                    if (value && (value.length > 255 || value.length < 3))
                                                    {
                                                        return Promise.reject(intl.formatMessage({id: `name_is_not_valid`}))
                                                    }

                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                        onBlur={() => chackIsValid()}
                                        name="firstName">
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_user_name"})}/>
                            </Form.Item>
                            <Form.Item label={<FormattedMessage id='user_last_name'/>}
                                        onBlur={() => chackIsValid()}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage id='last_name_validator'/>
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {

                                                    if (value && (value.length > 255 || value.length < 3))
                                                    {
                                                        return Promise.reject(intl.formatMessage({id: `last_name_is_not_valid`}))
                                                    }

                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                        name="lastName">
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_user_last_name"})}/>
                            </Form.Item>
                        </div>
                        <Form.Item label={<FormattedMessage id='email'/>}
                            onBlur={() => chackIsValid()}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id='email_is_required'/>
                                },
                                {
                                    type: 'email',
                                    message: <FormattedMessage id='email_is_not_a_valid_email'/>
                                }
                            ]}
                            name="email">
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_email"})} disabled={!!user?.id}/>
                        </Form.Item>
                        
                        <div className='select-role-block'>
                            <Form.Item label={<span className="capitalize"><FormattedMessage id='role_s'/></span>}
                                        onBlur={() => chackIsValid()}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage id='role_s_is_required'/>
                                            }
                                        ]}
                                        name={"roles"}
                                        validateTrigger="onChange"
                            >
                                <Select
                                    mode="multiple"
                                    size='default'
                                    placeholder={intl.formatMessage({id: "select_role_s"})}
                                    getPopupContainer={triggerNode => triggerNode.parentElement}
                                    style={{ width: '100%' }}
                                    showSearch
                                    dropdownClassName="role-drop-down"
                                    virtual={false}
                                    onChange={checkRolesResetBtn}
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {roles.map((role) => (
                                        <Select.Option key={role.id}>{role.name}</Select.Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            {
                                resetRolesBtn ?
                                    <Tooltip placement="right" title={<FormattedMessage id="delete_role_s" />}>
                                        <DeleteFilled onClick={() => {
                                                    form.setFieldsValue({roles: []});
                                                    setResetRolesBtn(false)
                                                }
                                            } style={{color: '#002766'}} />
                                    </Tooltip> : ""
                            }

                        </div>
                        
                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className={classname("miniBtn", {submit: fildeError })}
                                    loading={loading._add_user || loading['_edit_user' + user.id]}
                                    disabled={fildeError}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                            <div className="error_box">
                                <span className="block"><FormattedMessage id={"there_are_errors_on_the_form"}/>.</span>
                                <span className="block"><FormattedMessage id={"please_fix_them_before_continuing"}/>.</span>
                            </div>
                        </Form.Item>
                    </Form>
                    <span className="help-text">
                        <FormattedMessage id="indicates_a_required_field" />
                    </span>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        roles: state.roles
    }
}

const mapDispatchToProps = {
    addUser,
    editUser,
    getRoles,
    clearRoles
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(UserControlPopup));