import initialStore from "../initialStore";
import {
   
} from "@actions/actionTypes";

export default function appsStatistcsReducer(state = initialStore.appsStatistcs, action) {
    switch (action.type) {
        
        default:
            return state;
    }
}
