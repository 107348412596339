import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {Form, Input, Button, Select, Row, Col, Space} from 'antd';
import AppsProfit from "@components/Dashboard/AppsProfit/AppsProfit";
import Bets from "@components/Dashboard/Bets/Bets";
import Earning from "@components/Dashboard/Earning/Earning";
import EarningTable from "@components/Dashboard/EarningTable/EarningTable";
import "./style.sass";
// import {HmsToseconds, secondsToHms} from "@services/TradingRatesService";

function Dashboard({intl}) {
    const [form] = Form.useForm();
   
    return (
        <div className="content">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='dashboard'/></div>
            </div>
            <div className="dashboard">
               <Space direction="vertical" size={[0, 24]} style={{width: '100%', overflowX: 'auto'}}>
                    <Space direction="horizontal" size={[24, 0]} className="top-block">
                        <AppsProfit />
                        <Earning />
                        <Bets />
                   </Space>
                   <EarningTable className="bottom-block"/>
               </Space>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Dashboard));