import { LoadUser, Logout, CompleteLogout, RedirectToLogin, SigninSilent, manager, getPath, setUserOnManager} from "@services/AuthService";
import {
    EXTERNAL_LOGIN_SUCCESS,
    SET_LOGIN_ERROR,
    SET_REGISTRATION_ERROR,
    SET_USER,
    SHOW_LOGIN,
    SHOW_REGISTRATION,
    REQUEST_ADD_USER_LOGGING_OUT,
    SET_IS_END_SILENT,
    REQUEST_USER_LOGGING,
    REQUEST_FORGET_PASSWORD,
    REQUEST_FORGET_PASSWORD_RESEND,
    REQUEST_CHANGE_PASSWORD,
    SET_START_PATH,
    REQUEST_START_PATH,
    REQUEST_CHANGE_PROFILE_PASSWORD,
    REQUEST_CHANGE_PROFILE,
    UPDATE_USER,
    SET_RETURN_URL
} from "@actions/actionTypes";

export function loginUser(data) {
    return dispatch => {
         dispatch({
            type: REQUEST_USER_LOGGING,
            data
        })
    }
}

export function setUser(user) {
    return dispatch => {
        dispatch({type: SET_USER, user});
    }
}

export function SigninSilentCall() {
    return SigninSilent();
}

export function logoutUser() {
    return dispatch => {
        Logout();
    }
}

export function goToLoginForm() {
    return dispatch => {
        RedirectToLogin();
    }
}

export function removeUser() {
    return dispatch => {
        CompleteLogout().then(() => {
            dispatch({type: SET_USER, user: null});
        })
    }
}

export function callLogout(logoutId, id) {
    return dispatch => {
        dispatch({type: REQUEST_ADD_USER_LOGGING_OUT, data: {logoutId, id}});
    }
}

export function load() {
    return async (dispatch) => {
        try {
            const usr = await manager.signinPopup();
            const u = await manager.getUser();
            // console.log('users', u, usr)
        } catch (error) {
            console.log(error);
        }
    }
}

export function loadUser() {
    return async (dispatch) => {
        return LoadUser().then((user) => {
            if (user) {
                let path = getPath();
                dispatch({type: SET_START_PATH, path})
                dispatch({type: SET_USER, user});
                
            }
            dispatch({type: SET_IS_END_SILENT, isEndSilentRequest: true});
            SigninSilent().then(() => {
                LoadUser().then((user) => {
                    if (user) {
                        dispatch({type: SET_USER, user});
                    } else {
                        RedirectToLogin();
                    }
                    dispatch({type: SET_IS_END_SILENT, isEndSilentRequest: true});
                })
            }).catch((e) => {
                RedirectToLogin();
                dispatch({type: SET_IS_END_SILENT, isEndSilentRequest: true});
            });
            
        });
    };
}

export function forgetPassword(data) {
    return dispatch => {
        dispatch({type: REQUEST_FORGET_PASSWORD, data});
    }
}

export function forgetPasswordResend() {
    return dispatch => {
        dispatch({type: REQUEST_FORGET_PASSWORD_RESEND});
    }
}

export function changePassword(data) {
    return dispatch => {
        dispatch({type: REQUEST_CHANGE_PASSWORD, data});
    }
}

export function changeProfile(data) {
    return dispatch => {
        dispatch({type: REQUEST_CHANGE_PROFILE, data});
    }
}

export function changeProfilePassword(data) {
    return dispatch => {
        dispatch({type: REQUEST_CHANGE_PROFILE_PASSWORD, data});
    }
}



export function setReturnUrl(returnUrl) {
    return dispatch => {
        dispatch({type: SET_RETURN_URL, returnUrl});
    }
}