import initialStore from "../initialStore";
import { SET_RETURN_URL} from "@actions/actionTypes";

export default function returnUrlReducer(state = "", action) {
    switch (action.type) {
        case SET_RETURN_URL:
            return  action.returnUrl;
        default:
            return state;
    }
}
