import initialStore from "../initialStore";
import {
    UPDATE_PAGINATION,
    UPDATE_BY_START_PAGINATION,
    UPDATE_PAGINATION_TOTAL
} from "../actions/actionTypes";

export default function paginationReducer(state = initialStore.pagination, action) {
    switch (action.type) {
        case UPDATE_PAGINATION_TOTAL:
            return {...state, total: action.total};
        case UPDATE_PAGINATION:
            return {...action.pagination, total: state.total};
        case UPDATE_BY_START_PAGINATION:
            return {...initialStore.pagination, ...action.pagination};
        default:
            return state;
    }
}
