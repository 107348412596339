import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    sendSignInInstructions
} from "@actions/usersActions";
import {
    REQUEST_SEND_SIGN_IN_INSTRUCTIONS
} from "@actions/actionTypes";

import {Modal, Form, Input, Checkbox, Space, Button} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";


function SendSignInInstructionsPopup({show=true, intl, user, handleCancel, handleSended, loading, sendSignInInstructions}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        subscribeForError(REQUEST_SEND_SIGN_IN_INSTRUCTIONS, () => {
            handleSended();
        });
        form.setFieldsValue({email: user.email});
        return () => {
            unSubscribeForError(REQUEST_SEND_SIGN_IN_INSTRUCTIONS);
        }
    }, [])

    const onFinish = (data) => {
        sendSignInInstructions({...data, password: user.generatedPassword})
    }

    return (
        <>
            {
                <Modal
                    title={<FormattedMessage id='send_sign_in_instructions'/>}
                    centered
                    className={`formModal email-new-pass-modal`}
                    visible={show}
                    width={440}
                    onCancel={() => handleCancel()}
                    footer={false}>
                    <Form
                        layout="vertical"
                        form={form}
                        name="email-new-pass-form"
                        onFinish={onFinish}
                        validateTrigger="onBlur"
                        >
                        <Form.Item label={<FormattedMessage id='email'/>}
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id='email_is_required'/>
                                        },
                                        {
                                            type: "email",
                                            message: <FormattedMessage id='email_is_not_a_valid_email'/>
                                        }
                                    ]}
                                    name={"email"}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_email"})}/>
                        </Form.Item>
                        <Form.Item valuePropName="checked"
                                    name={"sendToMe"}>
                            <Checkbox><FormattedMessage id='send_copy_to_myself'/></Checkbox>
                        </Form.Item>

                        <div className="email-text">
                            <div>
                                <p>
                                    <span className="bold text-capitalize"><FormattedMessage id='subject'/>: </span>
                                    <FormattedMessage id='you_have_a_new_admin_account'/> 
                                </p> 
                                <p>
                                    <span><FormattedMessage id='welcome_to_your_new'/> </span>
                                    <span className="text-capitalize"><FormattedMessage id='admin_account'/></span>
                                </p> 
                                <p>
                                    <span className="text-capitalize">
                                        <FormattedMessage id='hello'/>,
                                    </span>
                                </p> 
                                <p>
                                    <span><FormattedMessage id='welcome_to_your_new'/> </span>
                                    <span className="text-capitalize"><FormattedMessage id='admin_account'/></span>
                                </p>  
                                <p>
                                    <FormattedMessage id='sign_in_to_your'/>
                                    <span className="text-capitalize"> <FormattedMessage id='admin_account'/> </span>
                                    <FormattedMessage id='to_access_the_admin_panel'/>.
                                </p>
                                <p className='global-link-color'>
                                <span className="bold text-capitalize"><FormattedMessage id='your_username'/>:</span> {user.email}
                                </p>
                                <p className='global-link-color'>
                                    <span className="bold text-capitalize"><FormattedMessage id='password'/>:</span> {user.generatedPassword}
                                </p>
                                <p>
                                    <FormattedMessage id='sign_in_button_appears_here'/>
                                </p>
                                <p>
                                    <span className="text-capitalize">
                                        <FormattedMessage id='regards'/>,
                                    </span>
                                    <br />
                                    <FormattedMessage id='the_workspace_team'/>
                                </p>
                            </div>
                        </div>
                        
                        <Form.Item className="MainModal--footer small-footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className="miniBtn"
                                    loading={loading._send_sign_in_instructions}
                                // disabled={currencyFieldError}
                            >
                                <FormattedMessage id='send'/>
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        appTreeData: state.appTreeData
    }
}

const mapDispatchToProps = {
    sendSignInInstructions
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(SendSignInInstructionsPopup));