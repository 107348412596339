import React, {useState} from "react";
import {Space, Dropdown, Menu} from "antd";
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import {StatisticsDurationsType} from "../../constants/Enums";


function HeaderTamplate({children, title, lastUpdate, selectedDuration = 0, changeDuration, className, ...resProps}) {
    const [selectedItem, setSelectedItem] = useState(StatisticsDurationsType[0])

    const selectDuration = (item, i) => {
        changeDuration(i);
        setSelectedItem(item);
    }

    const Durations = (<Menu className="duration-popup" selectedKeys={[selectedItem]}>
                            {StatisticsDurationsType.map((item, i) => {
                                return <Menu.Item key={StatisticsDurationsType[i]} onClick={() => selectDuration(item, i)}>
                                            <span>
                                                <FormattedMessage id={item} />
                                            </span>
                                        </Menu.Item>
                            })}
                        </Menu>);

    return (
        <Space direction="vertical" className={classnames('header-tamplate-block', className)} {...resProps}>
            <Space direction="horizontal" className="header">
                <Space direction="vertical" className="left">
                    <h3><FormattedMessage id={title}/></h3>
                    <span>{lastUpdate}</span>
                </Space>
                <Dropdown overlay={Durations} placement="bottomRight" trigger="click" className="right">
                    <span className="selected-duration">
                        <FormattedMessage id={StatisticsDurationsType[selectedDuration]}/>
                    </span>
                </Dropdown>
            </Space>
            
            <Space direction="horizontal">
                {children}
            </Space>
        </Space>
    )
}

//() => ({onChange: changeDuration})
export default HeaderTamplate;