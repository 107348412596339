import React, {useEffect, useRef, useState, useContext} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getUsers,
    editUser,
    clearUsers,
    clearLastCreatedUser
} from '@actions/usersActions';
import { REQUEST_ADD_USER } from '@actions/actionTypes';
import UserGridItem from "@components/UserCard";
import UserControlPopup from "@components/UserControlPopup"
import {
    ResponseContext
} from "../../../hooks/ResponseObserver";
import UserCardLoader from "@components/Loaders/UserCardLoader";
import ResetPasswordForPopup from "@components/ResetPasswordForPopup";
import ResetPassworSuccessfulPopup from "@components/ResetPassworSuccessfulPopup";
import EmailNewPasswordPopup from "@components/EmailNewPasswordPopup";
import SendSignInInstructionsPopup from "@components/SendSignInInstructionsPopup";
import ShowPassworPopup from "@components/ShowPassworPopup";

import "./style.sass";
function Users({intl, users, loading, lastCreatedUser, lastChangedUser, editUser, getUsers, clearUsers, clearLastCreatedUser}) {

    const [userControlPopup, setUserControlPopup] = useState('');
    const [userResetPassPopup, setUserResetPassPopup] = useState(false);
    const [singleUser, setSingleUser] = useState({});
    const [userResetPassSuccessfulPopup, setUserResetPassSuccessfulPopup] = useState(false);
    const [emailNewPassPopup, setEmailNewPassPopup] = useState(false);
    const [lastCreatedUserPassPopup, setLastCreatedUserPassPopup] = useState(false);
    const [sendSignInInstructionsPopup, setSendSignInInstructionsPopup] = useState(false);
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        subscribeForError(REQUEST_ADD_USER, () => {
            setUserControlPopup('');
        });
        return () => {
            unSubscribeForError(REQUEST_ADD_USER);
        }
    }, [])

    useEffect(() => {
        getUsers();
        return () => {
            clearUsers();
        }
    }, [])

    useEffect(() => {
        if (lastCreatedUser.id) {
            setSingleUser(lastCreatedUser);
            setLastCreatedUserPassPopup(true)
        }
    }, [lastCreatedUser])

    return (
        <div className="content">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='users'/></div>
                <div className="titleArea--BtnGroup">
                    <button className="mainBtn"
                            style={{border: 'unset'}}
                            onClick={() => setUserControlPopup("add")}>
                        <div className="icon"/>
                        <FormattedMessage id='add_user'/>
                    </button>
                </div>
            </div>
            <div className="users">
                <div className="users-grid">
                    {
                        loading._users === false ?
                        users.map(user => (
                            <UserGridItem item={user}
                                        loading={loading}
                                        key={user.id}
                                        clickEdit={() => {
                                            setSingleUser(user);
                                            setUserControlPopup ("edit");
                                        }}
                                        clickResetPass={() => {
                                            setUserResetPassPopup(true)
                                            setSingleUser(user);
                                        }}
                                        />
                        ))
                        :
                        Object.keys([...new Array(14)]).map(i => <UserCardLoader key={i}/>)
                    }
                </div>
            </div>
            {
                userControlPopup? <UserControlPopup handleCancel={
                                                    () => {
                                                        setUserControlPopup("");
                                                        setSingleUser({})
                                                    }
                                                }
                                                show={userControlPopup}
                                                user={singleUser}/> : ""
            }

            {
                userResetPassPopup? <ResetPasswordForPopup handleCancel={
                                                    () => {
                                                        setUserResetPassPopup(false);
                                                        setSingleUser({})
                                                    }
                                                }
                                                onSuccess={
                                                    () => {
                                                        setUserResetPassSuccessfulPopup(true);
                                                        setUserResetPassPopup(false);
                                                    }
                                                }
                                                user={singleUser}/> : ""
            }
            {
                userResetPassSuccessfulPopup? <ResetPassworSuccessfulPopup handleCancel={
                                                    () => {
                                                        setUserResetPassSuccessfulPopup(false);
                                                        setSingleUser({});
                                                    }
                                                }
                                                lastChangedUser={lastChangedUser}
                                                onSendPassByEmail={() => {
                                                    setEmailNewPassPopup(true);
                                                    setUserResetPassSuccessfulPopup(false);
                                                }}
                                                /> : ""
            }
            {
                emailNewPassPopup? <EmailNewPasswordPopup handleCancel={
                                                    () => {
                                                        setEmailNewPassPopup(false);
                                                        setUserResetPassSuccessfulPopup(true);
                                                    }
                                                }
                                                handleSended={
                                                    () => {
                                                        setEmailNewPassPopup(false);
                                                    }
                                                }
                                                lastChangedUser={lastChangedUser}/> : ""
            }
            {
                lastCreatedUserPassPopup? <ShowPassworPopup handleCancel={
                                                    () => {
                                                        setLastCreatedUserPassPopup(false);
                                                        setSingleUser({})
                                                        clearLastCreatedUser()
                                                    }
                                                }
                                                sendSignInInstructions={
                                                    () => {
                                                        setSendSignInInstructionsPopup(true);
                                                        setLastCreatedUserPassPopup(false);
                                                    }
                                                }
                                                user={singleUser}/> : ""
            }
            {
                sendSignInInstructionsPopup? <SendSignInInstructionsPopup handleCancel={
                                                    () => {
                                                        setSendSignInInstructionsPopup(false);
                                                        setLastCreatedUserPassPopup(true)
                                                    }
                                                }
                                                handleSended={() => {
                                                    setSendSignInInstructionsPopup(false);
                                                    setLastCreatedUserPassPopup(false);
                                                    setSingleUser({});
                                                }}
                                                user={singleUser}
                                                /> : ""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        users: state.users,
        loading: state.loading,
        lastCreatedUser: state.lastCreatedUser,
        lastChangedUser: state.lastChangedUser
    }
}

const mapDispatchToProps = {
    getUsers,
    editUser,
    clearUsers,
    clearLastCreatedUser
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Users));