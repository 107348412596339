import React from 'react';
import {FormattedMessage} from "react-intl";
import {Tooltip} from "antd";
import {EditOutlined} from '@ant-design/icons';

function RoleGridItem({item, clickEdit}) {
    return (
        <div className="role-item">
            <div className="role-item-header">
                <div className="title">{item.name}</div>
                <Tooltip placement="top" title={<FormattedMessage id="edit" />} overlayClassName="grid-item-tooltip">
                    <EditOutlined onClick={() => clickEdit(item)} />
                    
                </Tooltip>
            </div>
            <div className="role-description">{item.description}</div>
        </div>
    )
}

export default RoleGridItem