import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_ADD_ROLE,
    REQUEST_ROLES,
    SET_ROLES,
    REQUEST_EDIT_ROLE,
    SET_EDIT_ROLE,
    SET_ERROR_MSG,
    SET_ADD_ROLE
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";

function* fetchRoles() {
    let roles = yield call(GetResource, `/Role?IncludePermissions=true`);
    yield put({ type: SET_ROLES, roles: roles.data});
}

function* addRole({data}) {
    const role = yield call(PostResource, `/Role`, {...data});
    yield put({type: REQUEST_ROLES});
    yield put({type: SET_ADD_ROLE, role});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* editRole({data}) {
    const role =  yield call(PutResource, `/role/${data.id}`, data);
    yield put({type: SET_EDIT_ROLE, data: role.data});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}


function* rolesSaga() {
    yield takeLatest(REQUEST_ROLES,safe(onError, fetchRoles));
    yield takeLatest(REQUEST_ADD_ROLE, safe(onError, addRole));
    yield takeLatest(REQUEST_EDIT_ROLE, safe(onError, editRole));
}

export default rolesSaga;