import {all, call} from 'redux-saga/effects';
import errorSaga from "./errorSaga";
import appSaga from "./appsSaga";
import rolesSaga from "./rolesSaga";
import usersSaga from "./usersSaga";
import userSaga from "./userSaga";

export default function* rootSaga() {
    yield all([
        call(errorSaga),
        call(appSaga),
        call(rolesSaga),
        call(usersSaga),
        call(userSaga),
    ])
}