import React from 'react';
import {Skeleton} from "antd";

function RoleGridItemLoader(props) {
    return (
        <div className="role-item" {...props}>
            <div className="role-item-header">
                <div className="title">
                    <Skeleton.Button active={true} size='default' shape="default" block={false} style={{height: 14, width: 111}}/>
                </div>
                <Skeleton.Input style={{ width: 20, height: 20 }} active={true} size='default'/>
            </div>
            <div className="role-description">
                <Skeleton.Input style={{ width: 261, height: 18 }} active={true} size='default' block={true}/>
                <Skeleton.Input style={{ width: 241, height: 18 }} active={true} size='default' block={true}/>
                <Skeleton.Input style={{ width: 261, height: 18 }} active={true} size='default' block={true}/>
                <Skeleton.Input style={{ width: 180, height: 18 }} active={true} size='default' block={true}/>
            </div>
        </div>
    )
}

export default RoleGridItemLoader