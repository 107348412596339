export const DefaultLanguage = 'EN';
export const DefaultLanguageCode = 42;

export const Language = {
    ENGLISH: 1,
    RUSSIAN: 2
};

export const LanguageName = {
    1: "English",
    2: "Russian"
};

export const LanguageCode = {
    1: "EN",
    2: "RU"
};

export const LanguageForApi = {
    "EN": 1,
    "RU": 2,
    "AM": 3
};

export const LanguageCodesArr = [
    "EN",
    "RU"
];

export const DepositGroupStatus = {
    Inactive: 0,
    Active: 1
}

export const CouponGroups = {
    Daily: 1,
    Weekly: 2,
    monthly: 3,
    Yearly: 4,
    Forever: 5
}

export const CouponGroupsValueArr = Object.values(CouponGroups)
export const CouponGroupsNameArr = Object.keys(CouponGroups)

export const WalletsStatusType = {
    pending: 0,
    active: 1,
    wagered: 2,
    expired: 3,
    lost: 4
}

export const WalletStatusTypeArr = Object.keys(WalletsStatusType);

export const TransactionType = {
    1: "bet",
    2: "win",
    3: "lose",
    4: "refund",
    5: "deposit"
}
export const TransactionTypeArr = Object.keys(TransactionType);

export const TransactionStatus = {
    1: "success",
    2: "pending",
    3: "failed",
    4: "timeout"
}
export const TransactionStatusArr = Object.keys(TransactionStatus);

export const Methods = {
    0: "get",
    1: "post",
    2: "put",
    3: "delete",
    4: "patch"
}
export const MethodsArr = Object.values(Methods);

export const UsersStatus = ["active", "inactive", "blocked"]
    
export const UsersStatusType = {
    "active": 0,
    "blocked": 2,
    "inactive": 1
}

export const StatisticsDurationsType = ['today', 'yesterday', 'last_week', 'last_month', 'last_year']

export const chart_colors = {
    chart_color_1: '#681899',
    chart_color_2: '#282490',
    chart_color_3: '#116455',
    chart_color_4: '#289b70',
    chart_color_5: '#146cac',
    chart_color_hover_1: '#d2bae1',
    chart_color_hover_2: '#bebddd',
    chart_color_hover_3: '#b4cdc9',
    chart_color_hover_4: '#bdded3',
    chart_color_hover_5: '#b5cee2',
    chart_bg_gradient_color_1: 'rgba(0, 39, 102, 0.25)',
    chart_bg_gradient_color_2: 'rgba(24, 144, 255, 0.01)'
}