import React, {useState} from "react";
import {Space, Dropdown, Tooltip} from "antd";
import classnames from "classnames";
import AppItem from "./AppItem";
import {connect} from "react-redux";
import HeaderTamplate from '../HeaderTamplate';

function AppsProfit({appsStatistcs, ...resProps}) {
    const [selectedDuration, setSelectedDuration] = useState(0)

    const changeDuration = (i) => {
        // console.log(i)
        setSelectedDuration(i)
    }
    return (
        <HeaderTamplate className='apps-profit'
            changeDuration={changeDuration}
            title="apps_profit"
            selectedDuration={selectedDuration}
            lastUpdate={'Update 2 min. ago'}
            size={24}>
                <Space direction="horizontal" wrap size={[20, 21]}>
                    {appsStatistcs.map((app, i) => (
                        <AppItem key={i} item={app} style={{maxWidth: 192}}/>
                    ))}
                </Space>
                 
        </HeaderTamplate>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        appsStatistcs: state.appsStatistcs
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AppsProfit);