import initialStore from "../initialStore";
import {
    CLEAR_USERS,
    SET_USERS,
    SET_ERROR_MSG,
    SET_ADD_USER,
    SET_EDIT_USER,
    SET_RESAT_USER_PASS
} from "@actions/actionTypes";

export function usersReducer(state = initialStore.emptyArr, action) {
    switch (action.type) {
        case SET_USERS:
           return action.users.length? action.users : initialStore.users
        case CLEAR_USERS:
           return initialStore.emptyArr
        case SET_EDIT_USER:
            return state.map(user => {
                if (user.id == action.data.id) {
                    return action.data;
                }
                return user;
            })
        default:
            return state;
    }
}

export function lastCreatedUserReducer(state = initialStore.lastUser, action) {
    switch (action.type) {
        case SET_ADD_USER:
            return action.user.data
        default:
            return state;
    }
}

export function lastChangedUserReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case SET_RESAT_USER_PASS:
            return action.data.data
        default:
            return state;
    }
}