import React, {useState} from "react";
import {connect} from "react-redux";
import {Space, Dropdown, Tooltip} from "antd";
import classnames from "classnames";
import HeaderTamplate from '../HeaderTamplate';
import BetsChart from "./BetsChart";

function Bets({betsStatistcs, ...resProps}) {
    const [selectedDuration, setSelectedDuration] = useState(0)
    
    const changeDuration = (i) => {
        // console.log(i)
        setSelectedDuration(i)
    }

    return (
         <HeaderTamplate className='bets-block'
            changeDuration={changeDuration}
            title="bets"
            selectedDuration={selectedDuration}
            lastUpdate={'Update 5 min. ago'}
            size={50}>
                <BetsChart />
        </HeaderTamplate>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        betsStatistcs: state.betsStatistcs
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Bets);