import initialStore from "../initialStore";
import {
    CLEAR_APPS,
    SET_APPS,
    SET_EDIT_APP,
    SET_ERROR_MSG
} from "@actions/actionTypes";

export default function appsReducer(state = initialStore.apps, action) {
    switch (action.type) {
        case SET_APPS:
           return action.apps
        case SET_EDIT_APP:
            return state.map(app => {
                if (app.id === action.data.id) {
                    return action.data
                } else {
                    return app;
                }
            })
        case CLEAR_APPS:
           return initialStore.apps
        default:
            return state;
    }
}
