import Dashboard from "../pages/contents/Dashboard";
import Apps from "../pages/contents/Apps";
import Roles from "../pages/contents/Roles";
import Users from "../pages/contents/Users";
import EditProfile from "../pages/contents/EditProfile";

export default [
    {
        path: "/dashboard",
        component: Dashboard
    },
    {
        path:'/apps',
        component: Apps
    },
    {
        path: "/roles",
        component: Roles
    },
    {
        path: "/users",
        component: Users
    },
    {
        path: "/edit-profile",
        component: EditProfile
    }
 


    // ,
    // {
    //     path: "/t",
    //     component: t,
    //     routes: [
    //         {
    //         path: "/t/b",
    //         component: b
    //         },
    //         {
    //         path: "/t/c",
    //         component: c
    //         }
    //     ]
    // }
]