import React from 'react';
import { Line } from 'react-chartjs-2';
import {chart_colors} from "../../../constants/Enums";

let width, height, gradient;
function getGradient(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (gradient === null || width !== chartWidth || height !== chartHeight) {
    width = chartWidth;
    height = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.top, 0,  chartArea.bottom);
    gradient.addColorStop(0, chart_colors.chart_bg_gradient_color_1);
    gradient.addColorStop(1, chart_colors.chart_bg_gradient_color_2);
  }

  return gradient;
}

const data = {
  labels: [1, 4, 6, 7, 9, 12, 13, 15, 18, 23],
  datasets: [
          {
              data: [5, 3, 12, 10, 10, 7, 7, 5, 1, 10],
              fill: true,
              backgroundColor: (context) => {
                const chart = context.chart;
                const {ctx, chartArea} = chart;
                if (!chartArea) {
                  return null;
                }
                return getGradient(ctx, chartArea);
              },
              borderWidth: 1,
              borderColor: 'rgba(0, 39, 102, 0.7)',
          },
  ],
};

const options = {
  scales: {
    x: {
      title: {
        display: false,
      },
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        callback: () => {
          return ''
        }
      },
      grid: {
        drawOnChartArea: true,
        borderDash: [1, 1]
      }
    },
    y: {
      title: {
        display: false,
      },
      beginAtZero: true,
      ticks: {
        stepSize: 1,
        callback: () => {
          return ''
        }
      },
      grid: {
        drawOnChartArea: true,
        borderDash: [5, 5]
      }
    }
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    }
  }
};

const BetsChart = () => (
    <Line data={data} options={options} />
);

export default BetsChart;