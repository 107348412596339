import {
    REQUEST_USERS,
    CLEAR_USERS,
    REQUEST_USER,
    REQUEST_EDIT_USER,
    REQUEST_ADD_USER,
    REQUEST_RESAT_USER_PASS,
    REQUEST_SEND_PASS_BY_EMAIL,
    REQUEST_SEND_SIGN_IN_INSTRUCTIONS,
    SET_ADD_USER
} from "@actions/actionTypes";

export function getUsers() {
    return dispatch => {
        dispatch({
            type: REQUEST_USERS
        })
    }
}

export function addUser(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_USER,
            data,
            isOpenedByPopup: true
        })
    }
}

export function clearUsers() {
    return dispatch => {
        dispatch({
            type: CLEAR_USERS
        })
    }
}

export function getUser(id) {
    return dispatch => {
        dispatch({
            type: REQUEST_USER,
            id
        })
    }
}

export function editUser(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_USER,
            data,
            isOpenedByPopup: true
        })
    }
}

export function resetPassword(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_RESAT_USER_PASS,
            data,
            isOpenedByPopup: true
        })
    }
}

export function sendPassByEmail(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_SEND_PASS_BY_EMAIL,
            data,
            isOpenedByPopup: true
        })
    }
}

export function sendSignInInstructions(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_SEND_SIGN_IN_INSTRUCTIONS,
            data,
            isOpenedByPopup: true
        })
    }
}

export function clearLastCreatedUser() {
    return dispatch => {
        dispatch({ type: SET_ADD_USER, user: {data:{}}})
    }
}