import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import classNames from "classnames";
import ErrImg from "@assets/img/icon/error.svg";
import DoneImg from "@assets/img/icon/done.svg";
import WarnImg from "@assets/img/icon/warning.svg";
import InfoImg from "@assets/img/icon/info.svg";
import {FormattedMessage} from "react-intl";

const images = {
    'done': DoneImg,
    'error': ErrImg,
    'warning': WarnImg,
    'info': InfoImg
}


const AlertMessage = ({error}) => {
    const id = useRef(0)
    const refMap = useRef({})
    const timeout = useRef({})
    const [messages, setMessages] = useState([]);
    const [isExistsOpenedByPopup, setIsExistsOpenedByPopup] = useState(false);
    
    useEffect(() => {
        setIsExistsOpenedByPopup(!!messages.filter(m => m.isOpenedByPopup).length)
    }, [messages])

    useEffect(() => {
        if (error.messages) {
            id.current++
            let key = id.current + error.messages

            const index = messages.findIndex(msg => msg.messages === error.messages)
            if (index > -1) {

                let clonedMsg = JSON.parse(JSON.stringify(messages))
                clonedMsg.map((msg,idx) => {
                   if (idx === index) {
                       clearTimeout(timeout.current[msg.key])
                       timeout.current[msg.key] = setTimeout(() => {remove(msg.key)}, 5000)
                       msg.isUpdated= true
                   }
                })
                setMessages(clonedMsg)
            } else {
                timeout.current[key] = setTimeout(() => {remove(key)}, 5000)
                setMessages(prev => [{...error, key, isUpdated: false},...prev]);
            }
        }
    },[error])

    useEffect(() => {

        if (messages.length) {
            const clonedMsg = JSON.parse(JSON.stringify(messages))
            let updateState = false;
            clonedMsg.map(msg => {
                const element = refMap.current[msg.key]
                element.classList.add("in")
                setTimeout(() => {
                    element.classList.remove("out")
                   },1000
                    )
                if (msg.isUpdated) {
                    element.classList.add("out")
                    element.classList.remove("in")
                    updateState = true
                }
                return msg.isUpdated = false
            })
            if (updateState) {
                setMessages(clonedMsg)
            }

        }
    }, [messages])

    const remove = (key) => {
        setMessages(prev => prev.filter(msg => msg.key !== key))
        clearTimeout(timeout.current[key])
        delete timeout.current[key]
        delete refMap.current[key]
    }

    return(
        <div className={classNames('alertMessage', {"show": messages.length})} style={{zIndex: (isExistsOpenedByPopup? 2000 : 1000)}}>
            {
                messages.map(msg => (
                    <div ref={(ref) => ref ? refMap.current[msg.key] = ref : ""}
                        
                        key={msg.key}
                        className={classNames('msg in', msg.type)}>
                        {msg.type ?  <img src={images[msg.type]} alt="img" className="icon"/> : ""}
                        {msg.messages ? <FormattedMessage id={msg.messages}/> : ""}
                    </div>
                ))
            }

        </div>
    )


}


function mapStateToProps(state) {
    return {
        error: state.error,
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertMessage);
