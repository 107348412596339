import initialStore from "../initialStore";
import {
    CLEAR_APPS_TREE,
    SET_APPS_TREE,
    SET_ERROR_MSG
} from "@actions/actionTypes";

export default function appsTreeReducer(state = initialStore.apps, action) {
    switch (action.type) {
        case SET_APPS_TREE:
           return action.apps
        case CLEAR_APPS_TREE:
           return initialStore.apps
        default:
            return state;
    }
}
