import initialStore from "../initialStore";
import {
    SET_USER,
    SET_IS_END_SILENT,
    SET_CHANGE_PROFILE
} from "@actions/actionTypes";

export function userReducer(state = initialStore.user, action) {
    switch (action.type) {
        case SET_USER:
            if (!action.user) return action.user;
            return {
                ...action.user,
                profile: {
                    ...action.user.profile,
                    Apps: action.user.profile.Apps.split(',')
                }
            }
        case SET_CHANGE_PROFILE:
            return {
                ...state,
                profile: {
                    ...state,
                    email: action.data.email,
                    FirstName: action.data.firstName,
                    LastName: action.data.lastName,
                    ImageUrl: action.data.imageUrl
                }
            };
        default:
            return state;
    }
}


export function isEndSilentRequestReducer(state = true, action) {
    switch (action.type) {
        case SET_USER:
            return true;
        case SET_IS_END_SILENT:
            return action.isEndSilentRequest
        default:
            return state;
    }
}