import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    sendPassByEmail
} from "@actions/usersActions";
import {
    REQUEST_SEND_PASS_BY_EMAIL
} from "@actions/actionTypes";

import {Modal, Form, Input, Checkbox, Space, Button} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";


function EmailNewPasswordPopup({show=true, intl, lastChangedUser, handleCancel, handleSended, loading, sendPassByEmail}) {
    const [form] = Form.useForm();
    const [isShowPassInput, setIsShowPassInput] = useState(false)
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        subscribeForError(REQUEST_SEND_PASS_BY_EMAIL, () => {
            handleSended();
        });
        form.setFieldsValue({email: lastChangedUser.email})
        return () => {
            unSubscribeForError(REQUEST_SEND_PASS_BY_EMAIL);
        }
    }, [])

    useEffect(() => {
        if (lastChangedUser.id) {
            form.setFieldsValue({email: lastChangedUser.email});
        }
    }, [lastChangedUser])

    const onFinish = (data) => {
        sendPassByEmail({...data, password: lastChangedUser.generatedPassword});
    }

    const changeFormValue = (data) => {
        if (data.hasOwnProperty('isAutoGenerated')) {
            setIsShowPassInput(!!data.isAutoGenerated)
        }
    }


    return (
        <>
            {
                <Modal
                    title={<FormattedMessage id='email_new_password'/>}
                    centered
                    className={`formModal email-new-pass-modal`}
                    visible={show}
                    width={440}
                    onCancel={() => handleCancel()}
                    footer={false}>
                    <Form
                        layout="vertical"
                        form={form}
                        name="email-new-pass-form"
                        onValuesChange={changeFormValue}
                        onFinish={onFinish}
                        validateTrigger="onBlur"
                        >
                        <Form.Item label={<FormattedMessage id='email'/>}
                                    rules={[
                                        {
                                            required: true,
                                            message: <FormattedMessage id='email_is_required'/>
                                        },
                                        {
                                            type: "email",
                                            message: <FormattedMessage id='email_is_not_a_valid_email'/>
                                        }
                                    ]}
                                    name={"email"}>
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_email"})}/>
                        </Form.Item>
                        <Form.Item valuePropName="checked"
                                    name={"sendCopyToMyself"}>
                            <Checkbox><FormattedMessage id='send_copy_to_myself'/></Checkbox>
                        </Form.Item>

                        <div className="email-text">
                            <div>
                                <p>
                                    <span className="bold text-capitalize"><FormattedMessage id='subject'/>: </span>
                                    <FormattedMessage id='your_admin_account'/>
                                    <FormattedMessage id='password_has_been_resent_by_your_administrator'/> 
                                </p> 
                                <p>
                                    <FormattedMessage id='your_admin_account'/>
                                    <FormattedMessage id='password_has_been_resent'/> 
                                </p> 
                                <p>
                                    <span className="text-capitalize">
                                        <FormattedMessage id='hello'/>,
                                    </span>
                                </p> 
                                <p>
                                    <FormattedMessage id='your_administrator_has_reset_your'/> 
                                    <FormattedMessage id='your_admin_account'/>
                                </p> 
                                <p>
                                    <FormattedMessage id='click_reset_password_below_to_set_a_new_password'/>.
                                </p>
                                <p>
                                    <FormattedMessage id='sign_in_button_appears_here'/>
                                </p>
                                <p>
                                    <span className="text-capitalize">
                                        <FormattedMessage id='regards'/>,
                                    </span>
                                    <br />
                                    <FormattedMessage id='the_workspace_team'/>
                                </p>
                            </div>
                        </div>
                        
                        <Form.Item className="MainModal--footer small-footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className="miniBtn"
                                    loading={loading._send_pass_by_email}
                                // disabled={currencyFieldError}
                            >
                                <FormattedMessage id='send'/>
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        appTreeData: state.appTreeData
    }
}

const mapDispatchToProps = {
    sendPassByEmail
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EmailNewPasswordPopup));