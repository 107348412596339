import React, { useState, useEffect} from 'react'
import { Layout } from 'antd';
import LeftBare from '../../components/LeftBare'
import MainHeader from '../../components/Header'
import routes from "../../routes";
import {
    Switch,
    Redirect,
    Route,
    useHistory
  } from "react-router-dom";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined
} from '@ant-design/icons';
import "./style.sass";
import {connect} from "react-redux";

const { Sider, Content } = Layout;

// let redirectRoute = localStorage && localStorage.getItem('redirectRoute') || "/deposit_groups";
// redirectRoute = redirectRoute === '/' ? "/deposit_groups" : redirectRoute;

function MainPage({path, user}) {
    const [collapsed, setCollapsed] = useState(false);
    const history = useHistory();

    useEffect(() => {
        return history.listen((location, action) => {
            if (location.pathname.indexOf('logout') === -1 && location.pathname.indexOf('login') === -1) {
                localStorage.setItem('redirectRoute', location.pathname + location.search);
            }
        });
    }, [history]);

    return (
        <>
        <MainHeader apps={user?.profile?.Apps} isSuperAdmin={user?.profile?.IsSuperAdmin}/>
        <Layout>
            <Sider trigger={null} collapsible collapsed={collapsed}  collapsedWidth={56} width={256}>
                <LeftBare/>
                <div className="bar-trigger">
                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
                        className: 'trigger',
                        onClick: () => setCollapsed(!collapsed),
                    })}
                </div>
                
            </Sider>
            <Layout className="site-layout">
                <Content
                    className="site-layout-background"
                    style={{
                    margin: 24,
                    padding: '0 24px',
                    minHeight: 'auto'
                    }}
                >
                    <Switch>
                        {routes.map((route, i) => (
                            <Route
                                key={i}
                                path={route.path}
                                render={props => (
                                    <route.component {...props}/>
                                )}
                            />
                        ))}
                        <Redirect from="/callback/login" to={path} />
                        <Redirect from="/login" to={path} />
                        {/* <Route exact path="/:all" render={() => <Redirect to="not-found" />}/> */}
                    </Switch>
                </Content>
            </Layout>
        </Layout>
        </>
    )
}

function mapStateToProps(state) {
    return {
        path: state.path,
        user: state.user
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(MainPage);