import React, {useEffect, useState} from 'react';
import { Menu } from 'antd';

import { Link,  useLocation, useHistory } from "react-router-dom";
import {FormattedMessage} from "react-intl";
import "./style.sass";
import dashboardIcon from "@assets/img/icon/dashboard.svg";
import appsIcon from "@assets/img/icon/apps.svg";
import rolesIcon from "@assets/img/icon/roles.svg";
import usersIcon from "@assets/img/icon/users.svg";
  
function LeftBare() {
    const localHistory = useHistory();
    let location = useLocation();
    const [path, setPath] = useState(localHistory.location.pathname)

    useEffect(() => {
        let path = location.pathname;
        // if (path.indexOf('deposit_groups_detals') !== -1) {
        //     path = '/deposit_groups';
        // }
        setPath(path);
    },[location])

    const getPathParams = (path) => {
        let currentPath = location.pathname;
        let params = '';
        if (currentPath.indexOf(path) !== -1) {
            params = location.search;
        }
        return path + params;
    }

    return (
        <Menu  mode="inline" selectedKeys={[path]} className='left-bar-block'>
            <Menu.Item key="/dashboard" icon={<img src={dashboardIcon}/>}>
                <Link  to={getPathParams("/dashboard")}><FormattedMessage id='dashboard' /></Link >
            </Menu.Item>
            <Menu.Item key="/apps" icon={<img src={appsIcon}/>}>
                <Link  to={getPathParams("/apps")}><FormattedMessage id='apps' /></Link >
            </Menu.Item>
            <Menu.Item key="/roles" icon={<img src={rolesIcon}/>} >
                <Link  to={getPathParams("/roles")}><FormattedMessage id='roles' /></Link >
            </Menu.Item>
            <Menu.Item key="/users" icon={<img src={usersIcon}/>}>
                <Link  to={getPathParams("/users")}><FormattedMessage id='users' /></Link >
            </Menu.Item>
        </Menu>
    )
}

export default LeftBare;