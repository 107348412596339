import React, {useEffect} from 'react'
import {connect} from "react-redux";
import { Form, Input, Button, Checkbox, Card } from 'antd';
import { Link, Redirect, useHistory } from 'react-router-dom';
import {FormattedMessage} from "react-intl";
import {loginUser, setUser} from '@actions/userActions';
import {CompleteLogin} from "@services/AuthService";

function CallbackLogin({ user, setUser }) {

    let history = useHistory();

    useEffect(() => {
        CompleteLogin().then((user) => {
            if (user) {
                setUser(user);
                history.push(user.state);
            }
        });
    }, [setUser]);

    return (
        <div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    loginUser,
    setUser
}

export default connect(mapStateToProps, mapDispatchToProps)(CallbackLogin);