import React from "react";
import {connect} from "react-redux";
import { Form, Button, Space } from 'antd';
import {FormattedMessage} from "react-intl";
import {CheckCircleFilled} from '@ant-design/icons';
import {
    goToLoginForm
} from "@actions/userActions";

function PasswordChanged({returnUrl}) {
    const goToLoginForm = () => {
        window.location = returnUrl;
    }
    return (
        <div className="form-block pasword-changed">
            <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 24]}>
                <p className="login-description">
                    <FormattedMessage id='your_password_has_been_changed_successfully' /><br />
                    <FormattedMessage id='use_your_new_password_to_login_account' />
                </p>
            </Space>
            <div className="block-box">
                <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 20]}>
                    <span className="img">
                        <CheckCircleFilled />
                    </span>
                    <h2><FormattedMessage id='password_changed' /></h2>
                </Space>
                <Form
                    name="normal_login"
                    layout="vertical"
                    className="login-form"
                    initialValues={{ remember: true }}
                    requiredMark={false}
                    validateTrigger="onBlur"
                    onFinish={goToLoginForm}
                    >

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            <FormattedMessage id='continue_to_login_account'/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        returnUrl: state.returnUrl
    }
}

const mapDispatchToProps = {
    goToLoginForm
}

export default connect(mapStateToProps, mapDispatchToProps)(PasswordChanged);