import {
    REQUEST_APPS,
    CLEAR_APPS,
    REQUEST_APP,
    REQUEST_EDIT_APP,
    REQUEST_ADD_APP,
    REQUEST_APPS_TREE,
    CLEAR_APPS_TREE
} from "@actions/actionTypes";

export function getApps() {
    return dispatch => {
        dispatch({
            type: REQUEST_APPS
        })
    }
}

export function getAppsTree() {
    return dispatch => {
        dispatch({
            type: REQUEST_APPS_TREE
        })
    }
}

export function clearAppsTree() {
    return dispatch => {
        dispatch({
            type: CLEAR_APPS_TREE
        })
    }
}

export function addApp(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_APP,
            data,
            isOpenedByPopup: true
        })
    }
}

export function clearApps() {
    return dispatch => {
        dispatch({
            type: CLEAR_APPS
        })
    }
}

export function getApp(id) {
    return dispatch => {
        dispatch({
            type: REQUEST_APP,
            id,
            isOpenedByPopup: true
        })
    }
}

export function editApp(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_APP,
            data,
            isOpenedByPopup: true
        })
    }
}