import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    addRole,
    editRole
} from "@actions/rolesActions";
import {
    getAppsTree,
    clearAppsTree
} from "@actions/appsActions";
import {
    REQUEST_ADD_ROLE,
    REQUEST_EDIT_ROLE
} from "@actions/actionTypes";

import {Modal, Form, Input, Menu, Radio, Dropdown, Tree, Space, Button} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import TreeInput from "@components/TreeInput";


function RoleControlPopup({handleCancel, show, intl, role, loading, appTreeData, addRole, editRole, getAppsTree, clearAppsTree}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [fildeError, setFildeError] = useState(false);

    useEffect(() => {
        getAppsTree()
        if (role.id) {
            subscribeForError(REQUEST_EDIT_ROLE, () => {
                handleCancel();
            }, role.id);
        } else {
            subscribeForError(REQUEST_ADD_ROLE, () => {
                handleCancel();
            });
        }
        form.setFieldsValue({permissionIds: []})
        return () => {
            clearAppsTree()
            unSubscribeForError(REQUEST_ADD_ROLE);
            unSubscribeForError(REQUEST_EDIT_ROLE);
        }
    }, [])

    useEffect(() => {
        if(role.id) {
            const r = Object.assign({}, role);
            r.permissionIds = r.permissions.map(permission => permission.id)
            form.setFieldsValue(r);
        }
    }, [role])

    const onFinish = (data) => {
        // console.log(data)
        if (show === "add") {
            addRole(data)
        } else {
            editRole({...data, id: role.id})
        }
    }

    const formChange = (d) => {
        chackIsValid();
    }

    const chackIsValid = () => {
        setTimeout(() => {
            let isError = false;
            setFildeError(false);
            form.getFieldsError().some((field) => {
                isError = isError || field.errors.length > 0;
            })
            setFildeError(isError);
        }, 100)
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_role'/> :
                        <FormattedMessage id='edit_role'/>}
                    centered
                    className={`formModal role-modal`}
                    visible={true}
                    width={424}
                    onCancel={() => handleCancel()}
                    footer={false}>
                    <Form
                        layout="vertical"
                        form={form}
                        name="role-form"
                        onFinish={onFinish}
                        validateTrigger="onBlur"
                        onValuesChange={formChange}
                        onFinishFailed={() => chackIsValid()}
                        >
                        <div className='role-body'>
                            <Form.Item label={<span><FormattedMessage id='role_name'/></span>}
                                       onBlur={chackIsValid}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage id='name_validator'/>
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {

                                                    if (value && (value.length > 30 || value.length < 3))
                                                    {
                                                        return Promise.reject(intl.formatMessage({id: `role_name_is_not_valid`}))

                                                    }

                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                        name={"name"}>
                                <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_role_name"})}/>
                            </Form.Item>
                            <Form.Item label={<span><FormattedMessage id='description'/></span>}
                                       onBlur={chackIsValid}
                                        rules={[
                                            {
                                                required: true,
                                                message: <FormattedMessage id='description_validator'/>
                                            },
                                            ({getFieldValue}) => ({
                                                validator(_, value) {

                                                    if (value && (value.length > 256 || value.length < 3))
                                                    {
                                                        return Promise.reject(intl.formatMessage({id: `description_is_not_valid`}))
                                                    }

                                                    return Promise.resolve();
                                                },
                                            })
                                        ]}
                                        name={"description"}
                            >
                                <Input.TextArea rows={4} placeholder={intl.formatMessage({id: "type_description"})}/>
                            </Form.Item>
                            <Form.Item label={<span><FormattedMessage id='permission_s'/></span>}
                            className="permitions-item"
                                rules={[
                                    {
                                        required: true,
                                        message: <FormattedMessage id='permission_validator'/>
                                    }
                                ]}
                                onBlur={() => chackIsValid()}
                                name={"permissionIds"} >
                                <TreeInput treeData={appTreeData} loading={loading}/>
                            </Form.Item>
                        </div>
                        
                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className={classname("miniBtn", {submit: fildeError })}
                                    loading={loading._add_role || loading._edit_role}
                                    disabled={fildeError}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                            <div className="error_box">
                                <span className="block"><FormattedMessage id={"there_are_errors_on_the_form"}/>.</span>
                                <span className="block"><FormattedMessage id={"please_fix_them_before_continuing"}/>.</span>
                            </div>
                        </Form.Item>
                    </Form>
                    <span className="help-text">
                        <FormattedMessage id="indicates_a_required_field" />
                    </span>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        appTreeData: state.appTreeData
    }
}

const mapDispatchToProps = {
    addRole,
    editRole,
    getAppsTree,
    clearAppsTree
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(RoleControlPopup));