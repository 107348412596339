import { UserManager, WebStorageStateStore, Log} from "oidc-client";
import {PutResource} from "../utils/HttpUtil";
import {isHavePermissionThisApp, getTheAppsUrlToByRedirected} from "../utils/ChackPermission";
import routes from "../routes/index";

Log.logger = console;
Log.level = Log.INFO;

let path = '/';
if (window.location.pathname.indexOf('login') === -1 &&
    window.location.pathname.indexOf('logout') === -1 &&
    window.location.pathname.indexOf('change-password') === -1 &&
    window.location.pathname != '/') {
    path = window.location.pathname + window.location.search;
    localStorage.removeItem('redirectPath');
    // console.error(window.location.pathname + window.location.search);
} else {
    // console.log('else', window.location.pathname, window.location.search)
}
if (path == '/' || path.indexOf('change-password') != -1) {
    path = routes[0].path;
}

export const manager = new UserManager({
    authority: process.env.REACT_APP_IDP_AUTHORITY,
    client_id: process.env.REACT_APP_IDP_CLIENT_ID,
    redirect_uri: process.env.REACT_APP_IDP_REDIRECT_LOGIN, //`${process.env.REACT_APP_IDP_REDIRECT_LOGIN}?redirectUri=${search.get('redirectUri')}&path=${search.get('path')}`,
    response_type: "code",
    scope: "openid profile",
    loadUserInfo: true,
    response_mode: '',
    post_logout_redirect_uri: process.env.REACT_APP_IDP_REDIRECT_LOGOUT,
    automaticSilentRenew: true,
    silent_redirect_uri: process.env.REACT_APP_IDP_SILENT_LOGIN,
});
var user = null;

function setUser(u) {
    if (u) {
        if (isHavePermissionThisApp(u)) {
            user = u;
        } else {
            const url = getTheAppsUrlToByRedirected(u);
            if (url) {
                window.location = url;
            } else {
                Logout();
            }
        }
    }
    user = u;
}

function getUser(u) {
    return user;
}

export function getPath() {
    return path;
}

export function GetToken() {
    let u = getUser();
    return u ? u.access_token : null;
}

export async function LoadUser() {
    /*debugger;*/
    setUser(await manager.getUser());
    if (!getUser()) {
        RedirectToLogin();
        return null
    }
    return getUser();
}

export function RedirectToLogin() {
    manager.signinRedirect({data: path}).catch((error) => handleError(error));
}

export async function CompleteLogin() {
    //debugger;
    return await manager
        .signinRedirectCallback()
        .then((user) => {
            setUser(user);
            return user ? user : null;
        })
        .catch((error) => handleError(error));
}

export function Logout() {
    manager.signoutRedirect().catch((error) => handleError(error));
}

export async function CompleteLogout() {
    return await manager
        .signoutRedirectCallback()
        .then(() => {
            manager.removeUser();
        })
        .then(() => {
            setUser(null);
            RedirectToLogin();
        })
        .catch((error) => handleError(error));
}

function handleError(error) {
    debugger;
    if (error.message === "login_required") {
        Logout();
        RedirectToLogin();
    } else if (window.message = "No matching state found in storage") {
        Logout();
        RedirectToLogin();
    }
    console.error("Problem with authentication endpoint: ", error.message);
}

export async function SignUp() {
    const navigator = manager._redirectNavigator;
    const navigatorParams = {};
    return navigator.prepare(navigatorParams).then((handle) => {
        return manager
            .createSigninRequest(navigatorParams)
            .then((signinRequest) => {
                navigatorParams.url = signinRequest.url;
                navigatorParams.id = signinRequest.state.id;
                const returnUrl = navigatorParams.url.replace(
                    process.env.REACT_APP_IDP_AUTHORITY,
                    ""
                );
                navigatorParams.url =
                    process.env.REACT_APP_IDP_AUTHORITY +
                    "/Account/Register?ReturnUrl=" +
                    encodeURIComponent(returnUrl);
                return handle.navigate(navigatorParams);
            })
            .catch((err) => {
                if (handle.close) {
                    handle.close();
                }
                throw err;
            });
    });
}

export async function SigninSilent(user) {
    await manager.signinSilent().then((u) => {
        setUser(u);
    });
}

export async function removeUserFromStorage() {
    await manager.removeUser();
}

// export async function UpdateProfile(user) {
//     await PutResource("/profile", user);
//     await SigninSilent();
// }
