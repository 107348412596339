import {combineReducers} from "redux";
import settings from "./settingsReducer";
import {
    userReducer as user,
    isEndSilentRequestReducer as isEndSilentRequest
} from "./userReducer";
import error from "./errorReducer";
import langList from "./langListReducer";
import loading from "./loadingReducer";
import pagination from "./paginationReducer";
import responseErrors from "./responseErrorsReducer";
import apps from './appsReducer';
import roles from "./rolesReducer";
import {
    usersReducer as users,
    lastCreatedUserReducer as lastCreatedUser,
    lastChangedUserReducer as lastChangedUser
} from "./usersReducer";
import appTreeData from "./appTreeDataReducer";
import loginPageState from "./loginPageReducer";
import appsStatistcs from "./appsStatistcsReducer";
import earningTableStatistcs from "./earningTableStatistcsReducer";
import path from "./pathReducer";
import passwordHasBeenChanged from "./passwordHasBeenChangedReducer";
import returnUrl from "./returnUrlReducer";

const rootReducer = combineReducers({
    settings,
    user,
    isEndSilentRequest,
    langList,
    error,
    loading,
    pagination,
    responseErrors,
    apps,
    roles,
    users,
    appTreeData,
    loginPageState,
    appsStatistcs,
    earningTableStatistcs,
    lastCreatedUser,
    lastChangedUser,
    path,
    passwordHasBeenChanged,
    returnUrl
});

export default rootReducer;
