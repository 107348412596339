
import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_FORGET_PASSWORD,
    SET_FORGET_PASSWORD,
    SET_FORGET_PASSWORD_RESEND,
    REQUEST_FORGET_PASSWORD_RESEND,
    REQUEST_CHANGE_PASSWORD,
    SET_CHANGE_PASSWORD,
    SET_ERROR_MSG,
    REQUEST_USER_LOGGING,
    SET_USER_LOGGING,
    REQUEST_ADD_USER_LOGGING_OUT,
    SET_ADD_USER_LOGGING_OUT,
    REQUEST_CHANGE_PROFILE_PASSWORD,
    SET_CHANGE_PROFILE_PASSWORD,
    REQUEST_CHANGE_PROFILE,
    SET_CHANGE_PROFILE
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";
import {SigninSilentCall} from "@actions/userActions";

let lastSendedData = null

function* fetchForgotPass({data}) {
    lastSendedData = data;
    const emailHasBeenSent =  yield call(PostResource, `/Account/forget-password`, data);
    yield put({type: SET_FORGET_PASSWORD, emailHasBeenSent});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* resendForgotPass() {
    const emailHasBeenSent =  yield call(PostResource, `/Account/forget-password`, lastSendedData);
    yield put({type: SET_FORGET_PASSWORD_RESEND, emailHasBeenSent});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}


function* fetchChangePassword({data}) {
    const res =  yield call(PostResource, `/Account/reset-password`, data);
    yield put({type: SET_CHANGE_PASSWORD, passwordHasBeenChanged: res.data.passwordHasBeenChanged});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* fetchProfileChangePassword({data}) {
    const res =  yield call(PutResource, `/Account/change-password`, data);
    yield put({type: SET_CHANGE_PROFILE_PASSWORD, data: res});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* fetchProfileChange({data}) {
    const res =  yield call(PutResource, `/Account/profile-details`, data);
    yield call(SigninSilentCall);
    yield put({type: SET_CHANGE_PROFILE, data});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* fetchLogin({data}) {
    const res = yield call(PostResource, `/Account/sign-in`, data, null, {}, { credentials: 'include'});
    // console.error(JSON.parse(JSON.stringify(res.data)))
    if (res.data.isSucceeded || res.data.isFirstLogin) {
        // console.log(JSON.parse(JSON.stringify(res.data)))
        // console.log(res.data.redirectUrl);
        setTimeout(() => {
            // console.log("redirect")
            let url = new URL(res.data.redirectUrl);
            
            if (res.data.isFirstLogin) {
                url.searchParams.append("returnUrl", data.ReturnUrl);
            }
            // console.error("url", url);
            window.location = url.toString();
        }, 10)
    }
    yield put({type: SET_USER_LOGGING, data: res.data});
    // yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* fetchLogout({data}) {
    const res = yield call(GetResource, `/Account/sign-out?logoutId=${data.logoutId}`, null, {}, { credentials: 'include' });
    if (res.signOutIFrameUrl) {
        var iframe = document.createElement('iframe');
        iframe.width = 0;
        iframe.height = 0;
        iframe.class = 'signout';
        iframe.src = res.signOutIFrameUrl;
        document.body.appendChild(iframe);
      }
      console.log(JSON.stringify(res))
      if (res.postLogoutRedirectUri) {
        window.location = res.postLogoutRedirectUri;
      }
    yield put({type: SET_ADD_USER_LOGGING_OUT, data: {...res, id: data.id}});
    // yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* userSaga() {
    yield takeLatest(REQUEST_FORGET_PASSWORD, safe(onError, fetchForgotPass));
    yield takeLatest(REQUEST_FORGET_PASSWORD_RESEND, safe(onError, resendForgotPass));
    yield takeLatest(REQUEST_CHANGE_PASSWORD, safe(onError, fetchChangePassword));
    yield takeLatest(REQUEST_CHANGE_PROFILE_PASSWORD, safe(onError, fetchProfileChangePassword));
    yield takeLatest(REQUEST_CHANGE_PROFILE, safe(onError, fetchProfileChange));
    yield takeLatest(REQUEST_USER_LOGGING, safe(onError, fetchLogin));
    yield takeLatest(REQUEST_ADD_USER_LOGGING_OUT, safe(onError, fetchLogout));
}

export default userSaga;