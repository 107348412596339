import React from "react";
import {connect} from "react-redux";
import { Form, Button, Space } from 'antd';
import {FormattedMessage} from "react-intl";
import {
    forgetPasswordResend
} from "@actions/userActions";
import {CheckCircleFilled} from '@ant-design/icons';

function ForgotPassResend({loading, forgetPasswordResend}) {

    const onFinish = () => {
        forgetPasswordResend();
    }

    return (
        <div className="form-block resend">
            <Space direction="vertical" align="center" style={{width: '100%'}}>
                <p className="login-description" >
                    <FormattedMessage id='please_check_your_email' />
                </p>
            </Space>
            <div className="block-box">
                <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 20]}>
                    <span className="img">
                        <CheckCircleFilled />
                    </span>
                    <h2><FormattedMessage id='email_has_been_sent' /></h2>
                </Space>
                <Form
                    name="normal_login"
                    layout="vertical"
                    className="login-form"
                    initialValues={{ remember: true }}
                    requiredMark={false}
                    validateTrigger="onBlur"
                    onFinish={onFinish}
                    >
                    
                    <Form.Item className="">
                        <div className="login-link">
                            <span><FormattedMessage id="did_not_receive_the_link"/></span>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" loading={loading}>
                            <FormattedMessage id='resend'/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading._forget_password_resend
    }
}

const mapDispatchToProps = {
    forgetPasswordResend
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassResend);