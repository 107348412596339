export const FETCH_FAILED = "FETCH_FAILED";

export const REQUEST_USER_LOGGING = "REQUEST_USER_LOGGING";
export const SET_USER_LOGGING = "SET_USER_LOGGING";
export const REQUEST_ADD_USER_LOGGING_OUT = "REQUEST_ADD_USER_LOGGING_OUT";
export const SET_ADD_USER_LOGGING_OUT = "SET_ADD_USER_LOGGING_OUT";
export const SET_USER = "SET_USER";
export const SET_TOKEN = "SET_TOKEN";
export const SET_LOGIN_ERROR = "SET_LOGIN_ERROR";
export const SET_IS_END_SILENT = "SET_IS_END_SILENT";

export const SET_RESEND_FORGOT_PASSWORD = "SET_RESEND_FORGOT_PASSWORD";

// settings

// // PUT
// export const PUT_MIN_MAX_DURATIONS = "PUT_MIN_MAX_DURATIONS"

// //SET
// export const SET_MIN_MAX_DURATIONS = "SET_MIN_MAX_DURATIONS"

// //GET
// export const GET_MIN_MAX_DURATIONS = "GET_MIN_MAX_DURATIONS"

export const REQUEST_START_PATH = "REQUEST_START_PATH";
export const SET_START_PATH = "SET_START_PATH";


//errors
export const  SET_ERROR_MSG = "SET_ERROR_MSG"
export const  RESET_ERROR_MSG = "RESET_ERROR_MSG"
export const CLEAR_RESPONSE_ERROR = "CLEAR_RESPONSE_ERROR"
export const CHANGE_RESPONSE_ERROR = "CHANGE_RESPONSE_ERROR"

//loading

export const CHANGE_LOADING = 'CHANGE_LOADING';

//pagination
export const UPDATE_PAGINATION = 'UPDATE_PAGINATION';
export const UPDATE_BY_START_PAGINATION = 'UPDATE_BY_START_PAGINATION';
export const UPDATE_PAGINATION_TOTAL = 'UPDATE_PAGINATION_TOTAL';


export const SET_LANG_LIST = "SET_LANG_LIST"


//apps
export const SET_APPS = "SET_APPS";
export const REQUEST_APPS = "REQUEST_APPS";
export const REQUEST_APP = "REQUEST_APP";
export const CLEAR_APPS = "CLEAR_APPS";
export const REQUEST_EDIT_APP = "REQUEST_EDIT_APP";
export const SET_EDIT_APP = "SET_EDIT_APP";
export const REQUEST_ADD_APP = "REQUEST_ADD_APP";
export const SET_ADD_APP = "SET_ADD_APP";

export const REQUEST_APPS_TREE = "REQUEST_APPS_TREE";
export const SET_APPS_TREE = "SET_APPS_TREE";
export const CLEAR_APPS_TREE = "CLEAR_APPS_TREE";

//roles
export const SET_ROLES = "SET_ROLES";
export const REQUEST_ROLES = "REQUEST_ROLES";
export const REQUEST_ROLE = "REQUEST_ROLE";
export const CLEAR_ROLES = "CLEAR_ROLES";
export const REQUEST_EDIT_ROLE = "REQUEST_EDIT_ROLE";
export const SET_EDIT_ROLE = "SET_EDIT_ROLE";
export const REQUEST_ADD_ROLE = "REQUEST_ADD_ROLE";
export const SET_ADD_ROLE = "SET_ADD_ROLE";


//users
export const SET_USERS = "SET_USERS";
export const REQUEST_USERS = "REQUEST_USERS";
export const REQUEST_USER = "REQUEST_USER";
export const CLEAR_USERS = "CLEAR_USERS";
export const REQUEST_EDIT_USER = "REQUEST_EDIT_USER";
export const SET_EDIT_USER = "SET_EDIT_USER";
export const REQUEST_ADD_USER = "REQUEST_ADD_USER";
export const SET_ADD_USER = "SET_ADD_USER";
export const REQUEST_RESAT_USER_PASS = "REQUEST_RESAT_USER_PASS";
export const SET_RESAT_USER_PASS = "SET_RESAT_USER_PASS";
export const REQUEST_SEND_PASS_BY_EMAIL = "REQUEST_SEND_PASS_BY_EMAIL";
export const SET_SEND_PASS_BY_EMAIL = "SET_SEND_PASS_BY_EMAIL";
export const REQUEST_SEND_SIGN_IN_INSTRUCTIONS = "REQUEST_SEND_SIGN_IN_INSTRUCTIONS";
export const SET_SEND_SIGN_IN_INSTRUCTIONS = "SET_SEND_SIGN_IN_INSTRUCTIONS";

//user

export const REQUEST_FORGET_PASSWORD = "REQUEST_FORGET_PASSWORD";
export const SET_FORGET_PASSWORD = "SET_FORGET_PASSWORD";
export const REQUEST_FORGET_PASSWORD_RESEND = "REQUEST_FORGET_PASSWORD_RESEND";
export const SET_FORGET_PASSWORD_RESEND = "SET_FORGET_PASSWORD_RESEND";
export const REQUEST_CHANGE_PASSWORD = "REQUEST_CHANGE_PASSWORD";
export const SET_CHANGE_PASSWORD = "SET_CHANGE_PASSWORD";
export const REQUEST_CHANGE_PROFILE = "REQUEST_CHANGE_PROFILE";
export const SET_CHANGE_PROFILE = "SET_CHANGE_PROFILE";
export const REQUEST_CHANGE_PROFILE_PASSWORD = "REQUEST_CHANGE_PROFILE_PASSWORD";
export const SET_CHANGE_PROFILE_PASSWORD = "SET_CHANGE_PROFILE_PASSWORD";
export const UPDATE_USER = "UPDATE_USER";

export const SET_RETURN_URL = "SET_RETURN_URL";