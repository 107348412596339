import initialStore from "../initialStore";
import {
   
} from "@actions/actionTypes";

export default function earningTableStatistcsReducer(state = initialStore.earningTableStatistcs, action) {
    switch (action.type) {
        
        default:
            return state;
    }
}
