import React from "react";
import {Skeleton} from "antd";

function AppGridItemLoader({props}) {

    return (
        <div className="app-item" {...props}>
            <div>
                <h4><Skeleton.Button active={true} size='default' shape="default" block={false} style={{height: 14, width: 111}}/></h4>
                <Skeleton.Input style={{ width: 20, height: 20 }} active={true} size='default'/>
            </div>
            <div>
                <Skeleton.Avatar active={true} size="default" shape='circle' style={{height: 100, width: 100}}/>
                <div className='buttons-block'>
                    <Skeleton.Button active={true} size='default' shape="default" block={false} style={{marginBottom: 4, height:24}}/>
                    <Skeleton.Button active={true} size='default' shape="default" block={false} style={{height:24}}/>
                </div>
            </div>
        </div>
    )
}

export default AppGridItemLoader;