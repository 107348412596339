import React, {useEffect, useRef, useState, useContext} from "react";
import {connect} from "react-redux";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {Modal, Form, Input, Space, Button, Tooltip} from 'antd';
import {CopyFilled, CheckCircleFilled} from '@ant-design/icons';
import {FormattedMessage, injectIntl} from "react-intl";
import {copyLabel} from "../../utils/CommonUtils"
import UserEmptyImg from "@assets/img/userEmptyImg.svg";
import classname from "classnames";


function ShowPassworPopup({show = true, intl, user, handleCancel, loading, sendSignInInstructions, setDoneMsg, setErrorMsg}) {

    const [form] = Form.useForm();
    const passInputRef = useRef();

    useEffect(() => {
        form.setFieldsValue({password: user.generatedPassword});
    }, [user])

    const copyPass = () => {
        copyLabel(passInputRef.current, form.getFieldValue('password')).then(() => {
            setDoneMsg("password_is_copied", {isOpenedByPopup: true});
        }).catch(() => {
            setErrorMsg("something_wrong_the_text_is_not_copied", {isOpenedByPopup: true});
        })
    }

    return (<Modal
                title={<FormattedMessage id='your_new_user_can_start_using_admin_account'/>}
                centered
                closable={false}
                className={`formModal reset-pass-success-modal last-created-user-pass-modal`}
                visible={show}
                // onCancel={handleCancel}
                width={440}
                footer={false}>
                    <div className="user-info">
                        <img src={user.imageUrl || UserEmptyImg} />
                        <div className="detals">
                            <h4>{user.firstName} {user.lastName}</h4>
                            <h5>{user.email}</h5>
                        </div>
                    </div>
                <Form
                    layout="vertical"
                    form={form}
                    name="reset-pass-success-form"
                    validateTrigger="onBlur"
                    >
                    <Space direction="horizontal" className="pass-input-block" size={[12, 0]}>
                        <Form.Item label={<FormattedMessage id='password'/>}
                                    className="with-icon"
                                    name={"password"}>
                            <Input.Password readOnly={true} autoComplete="off" placeholder={intl.formatMessage({id: "type_password"})} ref={passInputRef}/>
                        </Form.Item>
                        <Tooltip placement="right" title={<FormattedMessage id="copy_password_to_clipboard" />}>
                            <CopyFilled onClick={copyPass}/>
                        </Tooltip>
                    </Space>
                    
                    <Form.Item className="MainModal--footer small-footer">
                        <button className="miniBtn default" onClick={sendSignInInstructions}>
                            <FormattedMessage id='send_sign_in_instructions'/>
                        </button>
                        <Button type="primary"
                                style={{height: 34}}
                                className="miniBtn"
                                onClick={handleCancel}
                        >
                            <FormattedMessage id='done'/>
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        appTreeData: state.appTreeData
    }
}

const mapDispatchToProps = {
    setDoneMsg,
    setErrorMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ShowPassworPopup));