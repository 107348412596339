import React, {useEffect, useRef, useState, useContext} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getApps,
    editApp,
    clearApps
} from '@actions/appsActions';
import { REQUEST_ADD_APP } from '@actions/actionTypes';
import AppGridItem from "@components/AppGridItem";
import AppControlPopup from "@components/AppControlPopup"
import {
    ResponseContext
} from "../../../hooks/ResponseObserver";
import AppGridItemLoader from "@components/Loaders/AppGridItemLoader";

import "./style.sass";
function Apps({intl, apps, loading, editApp, getApps, clearApps}) {

    const [appControlPopup, setAppControlPopup] = useState('');
    const [singleApp, setSingleApp] = useState({});
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);

    useEffect(() => {
        subscribeForError(REQUEST_ADD_APP, () => {
            setAppControlPopup('');
        });
        return () => {
            unSubscribeForError(REQUEST_ADD_APP);
        }
    }, [])

    useEffect(() => {
        getApps();
        return () => {
            clearApps();
        }
    }, [])

    return (
        <div className="content">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='apps'/></div>
                <div className="titleArea--BtnGroup">
                    <button className="mainBtn"
                            style={{border: 'unset'}}
                            onClick={() => setAppControlPopup("add")}>
                        <div className="icon"/>
                        <FormattedMessage id='add_app'/>
                    </button>
                </div>
            </div>
            <div className="apps">
                <div className="app-grid">
                    {
                        loading._apps === false ?
                        apps.map(app => (
                            <AppGridItem item={app}
                                        onOffApp={editApp}
                                        loading={loading}
                                        key={app.id}
                                        clickEdit={(item) => {
                                            setSingleApp(item);
                                            setAppControlPopup ("edit");
                                        }}/>
                        ))
                        :
                        Object.keys([...(new Array(30))]).map((i) => (<AppGridItemLoader key={i}/>))
                    }
                </div>
            </div>
            {
                appControlPopup? <AppControlPopup handleCancel={
                                                    () => {
                                                        setAppControlPopup("");
                                                        setSingleApp({})
                                                    }
                                                }
                                                show={appControlPopup}
                                                app={singleApp}/> : ""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        apps: state.apps,
        loading: state.loading
    }
}

const mapDispatchToProps = {
    getApps,
    editApp,
    clearApps
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Apps));