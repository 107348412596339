import initialStore from "../initialStore";
import {
    SET_FORGET_PASSWORD
} from "@actions/actionTypes";
export default function loinPageReducer(state = initialStore.loginPageState, action) {
    switch (action.type) {
        case SET_FORGET_PASSWORD:
            return {
                ...state,
                showResendForgotPassword: action.emailHasBeenSent
            }
        default:
            return state;
    }
}
