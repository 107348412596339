import React, {useEffect, useContext, useState, useRef} from "react";
import {connect} from "react-redux";
import {
    changeProfile
} from "@actions/userActions";
import {
    REQUEST_CHANGE_PROFILE
} from "@actions/actionTypes";

import { Form, Button, Input, Space } from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import { RedoOutlined, PlusOutlined, CloudUploadOutlined} from '@ant-design/icons';
import ImgUpload from "@components/ImgUpload/ImgUpload";

function ChangeProfile({intl, user, loading, changeProfile}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [fildeError, setFildeError] = useState(false);

    const imageUploadRef = useRef();

    const handleCancel = () => {
        console.log('handleCancel')
        requestAnimationFrame(() => {
            updateFormByUser();
            chackIsValid();
        });
    }

    const updateFormByUser = () => {
        const u = Object.assign({}, user);
        form.setFieldsValue(u);
    }

    const chackIsValid = () => {
        setTimeout(() => {
            let isError = false;
            setFildeError(false);
            form.getFieldsError().some((field) => {
                isError = isError || field.errors.length > 0;
            })
            setFildeError(isError);
        }, 100)
    }

    const formChange = (d) => {
        chackIsValid();
    }

    useEffect(() => {
        if (user.sub) {
            subscribeForError(REQUEST_CHANGE_PROFILE, () => {
                
            }, user.sub);
        }
        form.setFieldsValue(user)
        return () => {
            unSubscribeForError(REQUEST_CHANGE_PROFILE);
        }
    }, [])

    useEffect(() => {
        if(user.sub) {
            requestAnimationFrame(() => {
                updateFormByUser();
            });
        }
    }, [user])

    const onFinish = (data) => {
        changeProfile({
            email: data.email,
            firstName: data.FirstName,
            lastName: data.LastName,
            imageUrl: data.ImageUrl || ''
          });
    }

    const uploadImage = () => {
        if (imageUploadRef?.current) {
            imageUploadRef.current.click();
        }
    }

    const resetUploadImage = () => {
        form.setFieldsValue({ImageUrl: user.ImageUrl});
    }

    return (
        <>
            <h3 className="profile-title">
                <FormattedMessage id='profile_details'/>
            </h3>
            
            <Form
                layout="vertical"
                form={form}
                name="user-form"
                onFinish={onFinish}
                onValuesChange={formChange}
                scrollToFirstError
                onFinishFailed={() => chackIsValid()}
                validateTrigger="onBlur"
                >
                <Space direction="horizontal" size={[24, 0]}>
                    <Form.Item name="ImageUrl"
                                label={false}
                                onBlur={() => chackIsValid()}
                                // rules={[
                                //     {
                                //         required: true,
                                //         message: <FormattedMessage id='icon_validator'/>
                                //     },
                                // ]}
                                className="userImgUpload"
                                >
                        <ImgUpload icon={<PlusOutlined />}
                                    isOnlyImg={true}
                                    ref={imageUploadRef}
                                    uploadText={<FormattedMessage id='upload_avatar'/>} />
                    </Form.Item>
                    <Space direction="vertical" size={[0, 12]}>
                        <Space direction="horizontal" className="image-buttons">
                            <Button icon={<CloudUploadOutlined />}
                                    onClick={uploadImage}
                                    type="text">
                                <FormattedMessage id='upload'/>
                            </Button>
                            <Button icon={<RedoOutlined />}
                                    onClick={resetUploadImage}
                                    type="text">
                                <FormattedMessage id='reset'/>
                            </Button>
                        </Space>
                        <p className="image-types">
                            <FormattedMessage id="allowed_file_types_image" />
                        </p>
                    </Space>
                </Space>
                <Form.Item label={<FormattedMessage id='user_name'/>}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id='name_validator'/>
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {

                                        if (value && (value.length > 255 || value.length < 3))
                                        {
                                            return Promise.reject(intl.formatMessage({id: `name_is_not_valid`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}
                            onBlur={() => chackIsValid()}
                            name="FirstName">
                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_user_name"})}/>
                </Form.Item>
                <Form.Item label={<FormattedMessage id='user_last_name'/>}
                            onBlur={() => chackIsValid()}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id='last_name_validator'/>
                                },
                                ({getFieldValue}) => ({
                                    validator(_, value) {

                                        if (value && (value.length > 255 || value.length < 3))
                                        {
                                            return Promise.reject(intl.formatMessage({id: `last_name_is_not_valid`}))

                                        }
                                        return Promise.resolve();
                                    },
                                })
                            ]}
                            name="LastName">
                    <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_user_last_name"})}/>
                </Form.Item>

                <Form.Item label={<FormattedMessage id='email'/>}
                            style={{marginBottom: 32}}
                            onBlur={() => chackIsValid()}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id='email_is_required'/>
                                },
                                {
                                    type: 'email',
                                    message: <FormattedMessage id='email_is_not_a_valid_email'/>
                                }
                            ]}
                            name="email">
                            <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_email"})} disabled={true}/>
                </Form.Item>
                
                <Form.Item className="MainModal--footer">
                    <button type="reset" className="miniBtn default" onClick={handleCancel}>
                        <FormattedMessage id='discard'/>
                    </button>
                    <Button type="primary"
                            htmlType="submit"
                            style={{height: 36}}
                            className={classname("miniBtn", {submit: fildeError })}
                            loading={loading['_change_profile']}
                            disabled={fildeError}
                    >
                        <FormattedMessage id='save'/>
                    </Button>
                    <div className="error_box">
                        <span className="block"><FormattedMessage id={"there_are_errors_on_the_form"}/>.</span>
                        <span className="block"><FormattedMessage id={"please_fix_them_before_continuing"}/>.</span>
                    </div>
                </Form.Item>
            </Form>
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        user: state.user?.profile
    }
}

const mapDispatchToProps = {
    changeProfile
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeProfile));