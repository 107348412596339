import React, {useEffect, useContext, useRef} from 'react'
import {connect} from "react-redux";
import {removeUser, callLogout, goToLoginForm} from '@actions/userActions';
import { useLocation } from 'react-router-dom';
import {
    ResponseContext
} from "../../../hooks/ResponseObserver";
import {
    REQUEST_ADD_USER_LOGGING_OUT
} from "@actions/actionTypes";
import { removeUserFromStorage } from "@services/AuthService";

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function CompleteLogout({user, callLogout, removeUser, goToLoginForm}) {

    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    let query = useQuery();
    let isSucceeded = useRef(false);

    useEffect(() => {
        subscribeForError(REQUEST_ADD_USER_LOGGING_OUT, () => {
            if (isSucceeded.current) return;
            isSucceeded.current = true
            removeUser();
        }, user?.id || 1);
        return () => {
            unSubscribeForError(REQUEST_ADD_USER_LOGGING_OUT);
        }
    }, [])

    useEffect(() => {
        const logoutId = query.get("logoutId");
        if (logoutId) {
            removeUserFromStorage().then(() => {
                callLogout(logoutId, user?.id || 1);
            });
            
        } else {
            removeUserFromStorage().then(() => {
                goToLoginForm();
            });
        }
    }, [callLogout]);

    return (
        <div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    removeUser,
    callLogout,
    goToLoginForm
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteLogout);