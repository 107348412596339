import React, { useRef } from 'react'
import {FormattedMessage} from "react-intl";
import {Tooltip, Menu, Dropdown, Button, Select} from "antd";
import { UnlockOutlined } from '@ant-design/icons';
import {UsersStatus} from "../../constants/Enums";
import {EditOutlined} from '@ant-design/icons';
import UserEmptyImg from "@assets/img/userEmptyImg.svg";


function UserCard({item, clickEdit, clickResetPass, ...restprops}) {

    const ItemRef = useRef();

    const getMenu = (list) => {
        let menu = list.map((item) => (
            <Menu.Item key={item.key}>
                <span rel="noopener noreferrer">
                    {item.label}
                </span>
            </Menu.Item>
            ))
        

        return (
            <Dropdown overlay={<Menu>{menu}</Menu>}
                        trigger={["click"]}
                        placement="bottomCenter"
                        getPopupContainer={triggerNode => ItemRef.current}
                        overlayClassName="roles-drop-down"
                        arrow>
                <div style={{cursor: 'pointer'}}>...</div>
            </Dropdown>
        )
    }

    return (
        <div ref={ItemRef} className={"user-card-item " + UsersStatus[item.status]} {...restprops}>
            <div className="header">
                <img src={item.imageUrl || UserEmptyImg} />
                <span>{UsersStatus[item.status]}</span>
                <Tooltip placement="top" title={<FormattedMessage id="reset_password" />}>
                    <UnlockOutlined className="icon" onClick={clickResetPass}/>
                </Tooltip>
                <Tooltip placement="top" title={<FormattedMessage id="edit" />}>
                    <EditOutlined className="last" onClick={() => clickEdit(item.id)}/>
                </Tooltip>
            </div>
            <div className="body">
                <div className="detals">
                    <h4>{item.firstName} {item.lastName}</h4>
                    <h5>{item.email}</h5>
                </div>
                <div className="roles">
                    <div>
                        <span><FormattedMessage id="role_s" />:</span>
                        <span><FormattedMessage id="app_s" />:</span>
                    </div>
                    <div>
                        <div className="rol-block" >
                            <Select mode='multiple'
                                    style={{width: '100%'}}
                                    value={item.roles && item.roles.map(item => item.id) || []}
                                    options={item.roles && item.roles.map(item => ({value: item.id, label: item.name})) || []}
                                    maxTagCount='responsive'
                                    open={false}
                                    removeIcon={true}
                                    // tagRender={(props, a) => {
                                    //     // console.log(props, a)
                                    //     return props.label
                                    // }}
                                    maxTagPlaceholder={(list) => {
                                        // console.log(list)
                                        return getMenu(list)
                                    }}
                                    dropdownRender={() => {
                                        return false
                                    }}
                                />
                        </div>
                        <div className="app-block">
                            {item.applications && item.applications.map(app => (
                                <Tooltip key={app.id} placement="bottom" title={app.name} overlayClassName="app-tooltip">
                                    <img src={app.imageUrl} />
                                </Tooltip>
                            )) || ''}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserCard;