import React, {useState} from "react";
import {connect} from "react-redux";
import {Space, Dropdown, Tooltip} from "antd";
import classnames from "classnames";
import HeaderTamplate from '../HeaderTamplate';
import EarningChart from "@components/EarningChart";

function Earning({earningStatistcs, ...resProps}) {
    const [selectedDuration, setSelectedDuration] = useState(0)

    const changeDuration = (i) => {
        // console.log(i)
        setSelectedDuration(i)
    }

    return (
        <HeaderTamplate className='earning-block'
                        changeDuration={changeDuration}
                        title="earning"
                        selectedDuration={selectedDuration}
                        lastUpdate={'Update 5 min. ago'}
                        size={32}>
                        <EarningChart />
        </HeaderTamplate>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        earningStatistcs: state.earningStatistcs
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(Earning);