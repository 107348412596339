import React, {useEffect, useRef, useState, useContext} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import {
    getRoles,
    editRole,
    clearRoles
} from '@actions/rolesActions';
import RoleGridItem from "@components/RoleGridItem";
import RoleControlPopup from "@components/RoleControlPopup"

import "./style.sass";
import RoleGridItemLoader from "@components/Loaders/RoleGridItemLoader";
function Roles({intl, roles, loading, editRole, getRoles, clearRoles}) {

    const [roleControlPopup, setRoleControlPopup] = useState('');
    const [singleRole, setSingleRole] = useState({});

    useEffect(() => {
        getRoles();
        return () => {
            clearRoles();
        }
    }, [])

    return (
        <div className="content">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='roles'/></div>
                <div className="titleArea--BtnGroup">
                    <button className="mainBtn"
                            style={{border: 'unset'}}
                            onClick={() => setRoleControlPopup("add")}>
                        <div className="icon"/>
                        <FormattedMessage id='add_role'/>
                    </button>
                </div>
            </div>
            <div className="roles">
                <div className="role-grid">
                    {
                        loading._roles === false? 
                        roles.map(role => (
                            <RoleGridItem item={role}
                                        onOffRole={editRole}
                                        loading={loading}
                                        key={role.id}
                                        clickEdit={(role) => {
                                            setSingleRole(role);
                                            setRoleControlPopup ("edit");
                                        }}/>
                        ))
                        :
                        Object.keys([...new Array(14)]).map(i => <RoleGridItemLoader key={i}/>)
                    }
                </div>
            </div>
            {
                roleControlPopup? <RoleControlPopup handleCancel={
                                                    () => {
                                                        setSingleRole({})
                                                        setRoleControlPopup("");
                                                    }
                                                }
                                                show={roleControlPopup}
                                                role={singleRole}/> : ""
            }
        </div>
    )
}

function mapStateToProps(state) {
    return {
        roles: state.roles,
        loading: state.loading
    }
}

const mapDispatchToProps = {
    getRoles,
    editRole,
    clearRoles
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Roles));