import React, {useEffect, useState} from 'react'
import {connect} from "react-redux";
import { Form, Input, Button, Space } from 'antd';
import { useLocation, useHistory } from 'react-router-dom';
import {FormattedMessage, injectIntl} from "react-intl";
import {
    changePassword
} from '@actions/userActions';
import changePass from "@assets/img/icon/changePass.svg";
import ValidPasswordPopup from "@components/validPasswordPopup";
import {setReturnUrl} from "@actions/userActions";

function useQuery() {
    const { search } = useLocation();
  
    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function ChangePassword({intl, loading, passwordHasBeenChanged, changePassword, setReturnUrl}) {
    const [isFocusOnPassword, setIsFocusOnPassword] = useState(false);
    const [isFocusOnConfirmPassword, setIsFocusOnConfirmPassword] = useState(false);
    const [passwords, setPassword] = useState({password: '', confirmPassword: ''});
    const [isValidForm, setIsValidForm] = useState(true);
    const [validatePassInputs, setValidatePassInputs] = useState([true, true]);
    const [prevPasswordHasBeenChanged, setPrevPasswordHasBeenChanged] = useState(false);
    const history = useHistory();

    let query = useQuery();

    const onFinish = () => {
        const token = query.get("token");
        const returnUrl = query.get("returnUrl");
        const url = new URLSearchParams(returnUrl);
        const returnedUrl = new URL(url.get("redirect_uri"))
        setReturnUrl(returnedUrl.origin);
        if (token) {
            changePassword({...passwords, token});
        } else {
            console.error("do not a have token")
        }
    }

    const changeInput = (e) => {
        setPassword({...passwords, [e.target.name]: e.target.value || ''});
    }

    const setIsValid = (data) => {
        setValidatePassInputs(prev => {
            let newState = [...prev];
            newState[data.index] = data.isValid;
            return newState;
        })
    }

    useEffect(() => {
        setIsValidForm(validatePassInputs[0] && validatePassInputs[1] && passwords.password === passwords.confirmPassword);
    }, [validatePassInputs, passwords])

    useEffect(() => {
        if (passwordHasBeenChanged && !prevPasswordHasBeenChanged) {
            history.replace('password-changed');
        }
        return () => {
            setPrevPasswordHasBeenChanged(passwordHasBeenChanged);
        }
    }, [passwordHasBeenChanged])

    return (
        <div className="form-block change-pass-block">
            <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 24]}>
                <p className="login-description">
                    <FormattedMessage id='you_need_to_update_password' /><br />
                    <FormattedMessage id='because_this_is_the_first_time_you_are_logging' />
                </p>
            </Space>
            <div >
                <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 24]}>
                    <img src={changePass} />
                    <h2><FormattedMessage id='change_password' /></h2>
                </Space>
                <Form
                    name="change_pass"
                    layout="vertical"
                    className="change-pass-form"
                    requiredMark={false}
                    onFinish={onFinish}
                    >
                    <ValidPasswordPopup open={isFocusOnPassword} inputText={passwords.password} index={0} onValid={setIsValid}>
                        <Form.Item
                            name="password"
                            label={<FormattedMessage id='create_password' />}
                            rules={[{ required: true, message: <FormattedMessage id='please_input_your_password' /> }]}
                        >
                            <Input.Password
                                name="password"
                                placeholder={intl.formatMessage({id: 'type_password'})}
                                onChange={changeInput}
                                onFocus={() => setIsFocusOnPassword(true)} 
                                onBlur={() => setIsFocusOnPassword(false)}
                                />
                        </Form.Item>
                    </ValidPasswordPopup >
                    <ValidPasswordPopup open={isFocusOnConfirmPassword} inputText={passwords.confirmPassword} index={1} onValid={setIsValid}>
                        <Form.Item
                            name="confirmPassword"
                            label={<FormattedMessage id='confirm_password' />}
                            dependencies={['password']}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id='please_input_your_password' />
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                      }
                                      return Promise.reject(new Error(intl.formatMessage({id: 'the_two_passwords_that_you_entered_do_not_match'})));
                                    },
                                })
                            ]}
                        >
                            <Input.Password
                                name="confirmPassword"
                                placeholder={intl.formatMessage({id: 'type_password'})}
                                onChange={changeInput}
                                onFocus={() => setIsFocusOnConfirmPassword(true)}
                                onBlur={() => setIsFocusOnConfirmPassword(false)}/>
                        </Form.Item>
                    </ValidPasswordPopup >
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button" disabled={!isValidForm} loading={loading}>
                            <FormattedMessage id='change_password'/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading._change_password,
        passwordHasBeenChanged: state.passwordHasBeenChanged,
        returnUrl: state.returnUrl
    }
}

const mapDispatchToProps = {
    changePassword,
    setReturnUrl
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));