import React, {useEffect, useRef, useState, useContext} from "react";
import {connect} from "react-redux";
import {
    sendPassByEmail
} from "@actions/usersActions";
import {setDoneMsg, setErrorMsg} from "@actions/alertAction";
import {Modal, Form, Input, Space, Button, Tooltip} from 'antd';
import {CopyFilled, CheckCircleFilled} from '@ant-design/icons';
import {FormattedMessage, injectIntl} from "react-intl";
import {copyLabel} from "../../utils/CommonUtils"
import classname from "classnames";


function ResetPassworSuccessfulPopup({show = true, intl, handleCancel, loading, onSendPassByEmail, lastChangedUser, setDoneMsg, setErrorMsg}) {

    const [form] = Form.useForm();
    const passInputRef = useRef();

    useEffect(() => {
        form.setFieldsValue({password: lastChangedUser.generatedPassword});
    }, [])

    useEffect(() => {
        if (lastChangedUser.id) {
            form.setFieldsValue({password: lastChangedUser.generatedPassword});
        }
    }, [lastChangedUser])

    const copyPass = () => {
        copyLabel(passInputRef.current, form.getFieldValue('password')).then(() => {
            setDoneMsg("password_is_copied", {isOpenedByPopup: true});
        }).catch(() => {
            setErrorMsg("something_wrong_the_text_is_not_copied", {isOpenedByPopup: true});
        })
    }

    return (<Modal
                title={
                    <Space direction="vertical" className="title-block" align="center" size={[0, 26]}>
                        <CheckCircleFilled />
                        <span className="title">
                            <FormattedMessage id='reset_password_successful' />
                        </span>
                    </Space>
                }
                centered
                closable={false}
                className={`formModal reset-pass-success-modal`}
                visible={show}
                width={440}
                footer={false}>
                <Form
                    layout="vertical"
                    form={form}
                    name="reset-pass-success-form"
                    validateTrigger="onBlur"
                    >
                    <Space direction="horizontal" className="pass-input-block" size={[12, 0]}>
                        <Form.Item label={<FormattedMessage id='password'/>}
                                    className="with-icon"
                                    name={"password"}>
                            <Input.Password readOnly={true} autoComplete="off" placeholder={intl.formatMessage({id: "type_password"})} ref={passInputRef}/>
                        </Form.Item>
                        <Tooltip placement="right" title={<FormattedMessage id="copy_password_to_clipboard" />}>
                            <CopyFilled onClick={copyPass}/>
                        </Tooltip>
                    </Space>
                    
                    <Form.Item className="MainModal--footer small-footer">
                        <button className="miniBtn default" onClick={onSendPassByEmail}>
                            <FormattedMessage id='email_password'/>
                        </button>
                        <Button type="primary"
                                style={{height: 34}}
                                className="miniBtn"
                                onClick={handleCancel}
                        >
                            <FormattedMessage id='done'/>
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        appTreeData: state.appTreeData
    }
}

const mapDispatchToProps = {
    sendPassByEmail,
    setDoneMsg,
    setErrorMsg
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ResetPassworSuccessfulPopup));