import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Space } from 'antd';
import arrow_up from "@assets/img/icon/arrow_up.svg";
import arrow_down from "@assets/img/icon/arrow_down.svg";
import {chart_colors} from "../../constants/Enums";

const data = {
  // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
  legend: {
    display: false
  },
  datasets: [
    {
      label: '# of Votes',
      data: [20, 16, 10, 5, 2],
      backgroundColor: [
        chart_colors.chart_color_1,
        chart_colors.chart_color_2,
        chart_colors.chart_color_3,
        chart_colors.chart_color_4,
        chart_colors.chart_color_5
      ],
      hoverBackgroundColor: [
        chart_colors.chart_color_hover_1,
        chart_colors.chart_color_hover_2,
        chart_colors.chart_color_hover_3,
        chart_colors.chart_color_hover_4,
        chart_colors.chart_color_hover_5
      ],
      borderWidth: 0,
    },
  ],
};

const EarningChart = () => (
  <Space direction="vertical" size={[0, 12]} className="earning-chart">
    <span className="medium">280 000</span>
    <Space direction="horizontal" size={[8, 0]} className="isUp">
      +29% vs yesterday
      <img src={arrow_up} />
    </Space>
    <div className="chart-block">
      <Space direction="vertical" size={8}>
        <span className="medium">Club Rummy</span>
        <span className="isUp">+20%</span>
      </Space>
      <Doughnut data={data} style={{width: '100%'}} options={
        {
          cutout: 60,
          plugins: {
            tooltip: {
              backgroundColor:"#fff",
              displayColors: false,
              padding: 24,
              callbacks: {
                labelTextColor: function(context) {
                  return '#002766';
                },
                label: function(context) {
                  return context.dataset.label || '';
                }
              }
            }
        }}}/>
    </div>
    
  </Space>
);

export default EarningChart;