import moment from "moment-timezone";

export default {
    settings: {
        lang: {
            id: 1
        }
    },
    user: null,
    emptyObj: {},
    emptyArr: [],
    pagination: {
        page: 1,
        limit: 20,
        total: 5000
    },
    loginPageState:{
        showResendForgotPassword: false
    },
    apps: [],
    roles: [],
    appsStatistcs: [
        {
            name: 'Binary Option',
            count: '230k',
            statistics: '5',
            isPlus: true
        },
        {
            name: 'Ludo Classic',
            count: '2k',
            statistics: '1',
            isPlus: true
        },
        {
            name: 'Club Rummy',
            count: '98k',
            statistics: '20',
            isPlus: true
        },
        {
            name: 'Ludo Lands',
            count: '50k',
            statistics: '4',
            isPlus: true
        },
        {
            name: 'Pop the Ball',
            count: '-100k',
            statistics: '1',
            isPlus: false
        }
    ],
    earningTableStatistcs: [
        {
            appText: 'Binary Option',
            appImageUrl: "",
            profit: '230K',
            profitDifference: 5,
            sessions: '25k',
            sessionsDifference: -2,
            avgSession: '2h 15m 2s',
            avgSessionDifference: 5,
            bets: '2m (4k)',
            betsDifference: -43,
            avgBet: '500k',
            avgBetDifference: 5,
            wins: '1m (2k)',
            winsDifference: 2,
            refunds: '500 (2)',
            refundsDifference: 11,
            loses: '1m (2k)',
            losesDifference: -70

        },
        {
            appText: 'Ludo Classic',
            appImageUrl: "",
            profit: '2K',
            profitDifference: 5,
            sessions: '25k',
            sessionsDifference: -10,
            avgSession: '2h 15m 2s',
            avgSessionDifference: 5,
            bets: '2m (4k)',
            betsDifference: 43,
            avgBet: '500k',
            avgBetDifference: 5,
            wins: '1m (2k)',
            winsDifference: -2,
            refunds: '500 (2)',
            refundsDifference: -11,
            loses: '1m (2k)',
            losesDifference: -70

        },
        {
            appText: 'Club Rummy',
            appImageUrl: "",
            profit: '98k',
            profitDifference: 5,
            sessions: '25k',
            sessionsDifference: 2,
            avgSession: '2h 15m 2s',
            avgSessionDifference: 5,
            bets: '2m (4k)',
            betsDifference: 43,
            avgBet: '500k',
            avgBetDifference: -5,
            wins: '1m (2k)',
            winsDifference: 2,
            refunds: '500 (2)',
            refundsDifference: -11,
            loses: '1m (2k)',
            losesDifference: -70

        },
        {
            appText: 'Ludo Lands',
            appImageUrl: "",
            profit: '50k',
            profitDifference: 5,
            sessions: '25k',
            sessionsDifference: -2,
            avgSession: '2h 15m 2s',
            avgSessionDifference: 5,
            bets: '2m (4k)',
            betsDifference: 43,
            avgBet: '500k',
            avgBetDifference: -5,
            wins: '1m (2k)',
            winsDifference: 2,
            refunds: '500 (2)',
            refundsDifference: -11,
            loses: '1m (2k)',
            losesDifference: 70

        },
        {
            appText: 'Pop the Ball',
            appImageUrl: "",
            profit: '-100k',
            profitDifference: 5,
            sessions: '25k',
            sessionsDifference: -8,
            avgSession: '2h 15m 2s',
            avgSessionDifference: -5,
            bets: '2m (4k)',
            betsDifference: 43,
            avgBet: '500k',
            avgBetDifference: -5,
            wins: '1m (2k)',
            winsDifference: 2,
            refunds: '500 (2)',
            refundsDifference: -11,
            loses: '1m (2k)',
            losesDifference: -70

        }
    ],
    lastUser: {
        // id: "19ce85ee-6013-4f67-9a5c-84ac52277d07",
        // imageUrl: "https://intech-identity-api-dev.s3.amazonaws.com/869a3590-1e3b-4159-9835-f9b4c15cf8ae",
        // email: "hovhannes.mkrtchyan92@gmail.com",
        // generatedPassword: "qIxO$SO!XcqTddr$",
        // lastName: "Mkrtchyan",
        // firstName: "Hovhannes",
        // roles: ["b608754e-b78e-4d8f-b7d8-4376585ae0df"],
        // status: 0
    }
}