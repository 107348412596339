import React, {useEffect} from 'react'
import {connect} from "react-redux";
import { Form, Input, Button, Checkbox, Card, Space } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import {FormattedMessage, injectIntl} from "react-intl";
import {loginUser, loadUser} from '@actions/userActions';
import loginFormIcon from "@assets/img/icon/loginFormIcon.svg";
import {UserOutlined, LockFilled} from '@ant-design/icons';
import {GetResource} from '../../../utils/HttpUtil';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Login({user, loadUser, loginUser, intl, load}) {

    let query = useQuery();

    useEffect(() => {
        if (!user && !query.get("ReturnUrl") && !query.get("errorId")) {
            loadUser();
        } else if (query.get("errorId")) {
            GetResource('/Account/Error?errorId=' + query.get("errorId"))
        }
    }, [])

    const fetchLogin = (data) => {
        loginUser({...data, ReturnUrl: query.get("ReturnUrl")});
    }

    return (
        <div className="form-block">
            <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 24]}>
                <span className="login-lable">
                    <FormattedMessage id='welcome_to_the_team' />
                </span>
                <p className="login-description">
                    <FormattedMessage id='welcome_to_the_team_description' />
                </p>
            </Space>
            <div >
                <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 24]}>
                    <img src={loginFormIcon} />
                    <h2><FormattedMessage id='login_account' /></h2>
                </Space>
                <Form
                    name="normal_login"
                    layout="vertical"
                    className="login-form"
                    initialValues={{ remember: true }}
                    requiredMark={false}
                    onFinish={fetchLogin}
                    validateTrigger="onBlur"
                    >
                    <Form.Item
                        name="email"
                        label={false}
                        rules={[{
                            required: true,
                            message: <FormattedMessage id='email_is_required'/>
                        },
                        {
                            type: "email",
                            message: <FormattedMessage id='email_is_not_a_valid_email'/>
                        }
                    ]}
                    >
                        <Input placeholder={intl.formatMessage({id: 'type_email_id'})} prefix={<UserOutlined />}/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label={false}
                        rules={[{ required: true, message:  <FormattedMessage id='please_input_your_password'/> }]}
                    >
                            <Input.Password prefix={<LockFilled />} placeholder={intl.formatMessage({id: 'type_password'})}/>
                    </Form.Item>
                    <Form.Item className="flex-betwen">
                        <div className="flex-betwen">
                            <Form.Item name="remember" valuePropName="checked" noStyle>
                                <Checkbox><FormattedMessage id='remember_me'/></Checkbox>
                            </Form.Item>
                
                            <Link className="login-form-forgot" to="/forgot-password">
                                <FormattedMessage id='forgot_password'/>
                            </Link>
                        </div>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            <FormattedMessage id='login'/>
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        user: state.user
    }
}

const mapDispatchToProps = {
    loadUser,
    loginUser
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(Login));