import React, {useState} from "react";
import {Space} from "antd";
import classnames from "classnames";
import arrow_up from "@assets/img/icon/arrow_up.svg";
import arrow_down from "@assets/img/icon/arrow_down.svg";

function AppItem({item, ...resProps}) {
    return (
        <div className="app-item" {...resProps}>
            <div className="title">
                <h4>{item.name}</h4>
            </div>
            <div>
                <img className="app-image" src={item.imageUrl} />
                <Space direction="vertical" size={[0, 12]}>
                    <span className="medium">{item.count}</span>
                    <Space direction="horizontal" size={[8, 0]} className={classnames('arrow-block', {isUp: item.isPlus, isDown: !item.isPlus})}>
                        <span>{(item.isPlus? '+' : '-') + item.statistics}</span>
                        <img className="statistics-icon" src={item.isPlus? arrow_up : arrow_down} />
                    </Space>
                </Space>
            </div>
        </div>
    )
}

export default AppItem;