import {
    REQUEST_ROLES,
    CLEAR_ROLES,
    REQUEST_ROLE,
    REQUEST_EDIT_ROLE,
    REQUEST_ADD_ROLE
} from "@actions/actionTypes";

export function getRoles() {
    return dispatch => {
        dispatch({
            type: REQUEST_ROLES
        })
    }
}

export function addRole(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_ADD_ROLE,
            data,
            isOpenedByPopup: true
        })
    }
}

export function clearRoles() {
    return dispatch => {
        dispatch({
            type: CLEAR_ROLES
        })
    }
}

export function getRole(id) {
    return dispatch => {
        dispatch({
            type: REQUEST_ROLE,
            id
        })
    }
}

export function editRole(data) {
    return dispatch => {
        dispatch({
            type: REQUEST_EDIT_ROLE,
            data,
            isOpenedByPopup: true
        })
    }
}