import React, { useEffect, useState } from 'react'
import { Layout, Tooltip, Dropdown } from 'antd';
import {FormattedMessage} from "react-intl";
import UserAccount from "../UserAccount";
import "./style.sass";
import logo from "@assets/img/icon/switch_to.svg";
import logo_core from "@assets/img/icon/switch_to/logo_core.svg";
import SwitchTo from "@components/SwitchTo";
import classnames from 'classnames';

const { Header } = Layout;

function MainHeader({apps, isSuperAdmin}) {
    const [isVisibleSwitchTo, setIsVisibleSwitchTo] = useState(false);
    const [isVisibleTooltip, setIsVisibleTooltip] = useState(false);
    const [showSwitchTo, setShowSwitchTo] = useState(false);

    useEffect(() => {
        setIsVisibleTooltip(false)
    }, [isVisibleSwitchTo])

    useEffect(() => {
        setShowSwitchTo(apps?.length && apps.length > 1 || isSuperAdmin)
    }, [apps])

    return (
        <Header className="site-layout-background header">
            <div >
                <div className={classnames("logo-site", {show: showSwitchTo})}>
                    <Dropdown overlayClassName="logoDropDown" overlay={<SwitchTo />} placement="bottomLeft" trigger="click" onVisibleChange={setIsVisibleSwitchTo}>
                        <Tooltip placement="bottomLeft"
                                 overlayClassName="logoTooltip"
                                 title={<FormattedMessage id="switch_to_" />}
                                 visible={isVisibleTooltip && !isVisibleSwitchTo}
                                 onVisibleChange={setIsVisibleTooltip}>
                            <img src={logo} />
                        </Tooltip>
                    </Dropdown>
                </div>
                <div className="time-block centeredBox">
                    <img src={logo_core} />
                    <span>core</span>
                </div>
                <div className="header-account">
                    <UserAccount />
                </div>
            </div>
        </Header>
    )
}

export default MainHeader;