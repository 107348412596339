import {call, put, takeLatest} from "redux-saga/effects";
import {
    REQUEST_ADD_APP,
    REQUEST_APPS,
    SET_APPS,
    REQUEST_EDIT_APP,
    SET_EDIT_APP,
    SET_ERROR_MSG,
    SET_ADD_APP,
    SET_APPS_TREE,
    REQUEST_APPS_TREE
} from "@actions/actionTypes";
import {GetResource, PostResource, PutResource} from "../../utils/HttpUtil";
import {safe, onError} from "./handlerError";
import {cache, loadImages} from "../../utils/SuspenseImage";

function* fetchApps() {
    let apps = yield call(GetResource, `/Application`);
    yield call(loadImages, apps.data);
    yield put({ type: SET_APPS, apps: apps.data});
}

function* fetchAppsTree() {
    let apps = yield call(GetResource, `/Application?IgnoreEmptyApps=true`);
    let appsTree = apps.data.map(app => ({
        key: app.id,
        title: app.name,
        children: app.permissions.map(permission => ({
            key: permission.id,
            title: permission.name
        }))
    }))
    yield put({ type: SET_APPS_TREE, apps: appsTree});
}

function* addApp({data}) {
    const app = yield call(PostResource, `/Application`, data);
    yield put({type: REQUEST_APPS});
    yield put({type: SET_ADD_APP, app});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* editApp({data}) {
    const app =  yield call(PutResource, `/Application/${data.id}`, data);
    yield put({type: SET_EDIT_APP, data: app.data});
    yield put({type: SET_ERROR_MSG, data: {type: 'done', messages: "success"}});
}

function* appsSaga() {
    yield takeLatest(REQUEST_APPS,safe(onError, fetchApps));
    yield takeLatest(REQUEST_APPS_TREE,safe(onError, fetchAppsTree));
    yield takeLatest(REQUEST_ADD_APP, safe(onError, addApp));
    yield takeLatest(REQUEST_EDIT_APP, safe(onError, editApp));
}

export default appsSaga;