import React, {useEffect, useRef, useState, useContext} from "react";
import {connect} from "react-redux";
import {FormattedMessage, injectIntl} from "react-intl";
import ChangeProfile from "@components/UserProfile/ChangeProfile";
import ChangeProfilePassword from "@components/UserProfile/ChangePassword";
import "./style.sass";
import { Space } from "antd";
function EditProfile({intl, loading}) {

    return (
        <div className="content">
            <div className="titleArea">
                <div className="titleArea--title"><FormattedMessage id='profile'/></div>
                <div className="titleArea--BtnGroup">
                    
                </div>
            </div>
            <div className="edit-profile">
                <Space direction="horizontal" className="profile-block" size={[24, 0]}>
                    <div className="left">
                        <ChangeProfile />
                    </div>
                    <div className="right">
                        <ChangeProfilePassword />
                    </div>
                </Space>
            </div>
        </div>
    )
}


function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps = {
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EditProfile));