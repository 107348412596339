import React from 'react'
import {FormattedMessage} from "react-intl";
import {UsersStatus} from "../../constants/Enums"; 
import {Skeleton} from "antd";

function UserCardLoader(props) {

    const status = Math.round(Math.random() * 3);

    return (
        <div className={"user-card-item loading"} {...props}>
            <div className="header">
                <Skeleton.Avatar active={true} size="default" shape='circle' style={{height: 72, width: 72}} className="img"/>
                <span><Skeleton.Input style={{ width: 100, height: 20 }} active={true} size='default'/></span>
                <Skeleton.Input style={{ width: 20, height: 20 }} active={true} size='default'/>
            </div>
            <div className="body">
                <div className="detals">
                    <h4><Skeleton.Button active={true} size='default' shape="default" block={false} style={{height: 14, width: 111}}/></h4>
                    <h5><Skeleton.Button active={true} size='default' shape="default" block={false} style={{height: 14, width: 121}}/></h5>
                </div>
                <div className="roles">
                    <div>
                        <span><FormattedMessage id="role_s" />:</span>
                        <span><FormattedMessage id="app_s" />:</span>
                    </div>
                    <div>
                        <div className="rol-block" >
                            <Skeleton.Button active={true} size='default' shape="default" block={false} style={{width: 92, height:24}} className="ant-select-selection-item"/>
                            <Skeleton.Button active={true} size='default' shape="default" block={false} style={{width: 80, height:24}} className="ant-select-selection-item"/>
                            <Skeleton.Button active={true} size='default' shape="default" block={false} style={{width: 100, height:24}} className="ant-select-selection-item"/>
                        </div>
                        <div className="app-block">
                            {Object.keys([...new Array(Math.round(Math.random() * 5) || 1)]).map(i => (
                                <Skeleton.Avatar active={true} size="default" shape='circle' key={i}/>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserCardLoader;