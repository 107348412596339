import React from "react";
import {connect} from "react-redux";
import { Form, Input, Button, Space } from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import { Link } from 'react-router-dom';
import {
    forgetPassword
} from "@actions/userActions";
import resetPassIcon from "@assets/img/icon/resetPassIcon.svg";

function ForgotPassForm({intl, loading, forgetPassword}) {
    const onFinish = (data) => {
        forgetPassword(data)
    }
    return (
        <div className="form-block forgot">
            <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 24]}>
                <span className="login-lable">
                    <FormattedMessage id='forgot_password' />
                </span>
                <p className="login-description" >
                    <FormattedMessage id='forgot_password_description_1' /><br />
                    <FormattedMessage id='forgot_password_description_2' />
                </p>
            </Space>
            <div className="block-box">
                <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 24]}>
                    <img src={resetPassIcon} />
                    <h2><FormattedMessage id='reset_password' /></h2>
                </Space>
                <Form
                    name="forgot_password_form"
                    layout="vertical"
                    className="login-form"
                    requiredMark={false}
                    validateTrigger="onBlur"
                    onFinish={onFinish}
                    >
                    <Form.Item
                        name="email"
                        label={<FormattedMessage id='email'/>}
                        rules={[
                            {
                                required: true,
                                message: <FormattedMessage id='please_input_your_email'/>
                            },
                            {
                                type: "email",
                                message: <FormattedMessage id='please_input_your_email'/>
                            }
                        ]}
                    >
                        <Input  placeholder={intl.formatMessage({id: 'type_email'})} />
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary"
                                htmlType="submit"
                                className="login-form-button"
                                loading={loading}>
                            <FormattedMessage id='request_reset_link'/>
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Space direction="vertical" align="center" style={{width: '100%'}} size={[0, 24]}>
                            <Link to="/login">
                                <FormattedMessage id='back_to_login'/>
                            </Link>
                        </Space>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading._forget_password
    }
}

const mapDispatchToProps = {
    forgetPassword
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ForgotPassForm));