import React, {useEffect, useState} from "react";
import {Tree, Skeleton, Space} from "antd"; 

function TreeInput({value = [], loading, onChange, treeData = [], onBlur = () => {}}) {

    const [parentIds, setParentIds] = useState([])

    const [expandedKeys, setExpandedKeys] = useState([]);
    const [checkedKeys, setCheckedKeys] = useState(value);
    const [autoExpandParent, setAutoExpandParent] = useState(true);

    useEffect(() => {
        setCheckedKeys(value)
    }, [value])

    useEffect(() => {
        setParentIds(treeData.map(item => item.key))
    }, [treeData])

    const onExpand = (expandedKeysValue) => {
        setExpandedKeys(expandedKeysValue);
        setAutoExpandParent(false);
    };

    const onCheck = (checkedKeysValue) => {
        const newSelected = checkedKeysValue.filter(id => parentIds.indexOf(id) === -1);
        setCheckedKeys(newSelected);
    };

    useEffect(() => {
        onChange(checkedKeys);
        if (checkedKeys != value) {
            setTimeout(() => onBlur(), 10);
        }
    }, [checkedKeys])

    return (
        <>
        {
            !loading._apps_tree &&
            <Tree
                checkable
                selectable={false}
                onExpand={onExpand}
                expandedKeys={expandedKeys}
                autoExpandParent={autoExpandParent}
                onCheck={onCheck}
                checkedKeys={checkedKeys}
                treeData={treeData}
                /> ||
            <Space direction="horizontal" size={[8, 0]} className="tree-input-loading">
                <Skeleton active paragraph={{ rows: 6, width: 18 }} title={false}/>
                <Skeleton active paragraph={{ rows: 6, width: 100 }} title={false} />
            </Space>
        }
        
        </>
    )
}

export default TreeInput;