import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import {Button, Tooltip} from "antd";
import classnames from "classnames";
import {EditOutlined} from '@ant-design/icons';

function AppGridItem({item, onOffApp, clickEdit, loading, ...resProps}) {

    const onOff = (status) => {
        onOffApp({...item, isActive: !!status});
    }

    return (
        <div className="app-item" {...resProps}>
            <div>
                <h4>{item.name}</h4>
                <Tooltip placement="top" title={<FormattedMessage id="edit" />}
                         overlayClassName="grid-item-tooltip">
                    <EditOutlined onClick={() => clickEdit(item)}/>
                </Tooltip>
            </div>
            <div>
                <img className="app-image" src={item.imageUrl} />
                <div className={classnames(item.isActive? 'enable' : 'disable', 'buttons-block') }>
                    <Button disabled={loading['_edit_app' + item.id]} type="primary" onClick={() => onOff(1)} className="miniBtn btnRadio">
                        <FormattedMessage id="on" />
                    </Button>
                    <Button disabled={loading['_edit_app' + item.id]} type="default"  onClick={() => onOff(0)} className="miniBtn btnRadio default">
                        <FormattedMessage id="off" />
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default AppGridItem;