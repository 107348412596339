import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    changeProfilePassword
} from "@actions/userActions";
import {
    REQUEST_CHANGE_PROFILE_PASSWORD
} from "@actions/actionTypes";

import { Form, Button, Input, Space } from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import ValidPasswordPopup from "@components/validPasswordPopup";

function ChangeProfilePassword({intl, loading, user, changeProfilePassword}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [fildeError, setFildeError] = useState(false);
    const [isFocusOnPassword, setIsFocusOnPassword] = useState(false);
    const [isFocusOnConfirmPassword, setIsFocusOnConfirmPassword] = useState(false);
    const [passwords, setPassword] = useState({password: '', confirmPassword: ''});
    const [validatePassInputs, setValidatePassInputs] = useState([true, true]);
    const [isValidForm, setIsValidForm] = useState(true);

    const handleCancel = () => {
        // console.log('handleCancel')
    }

    const chackIsValid = () => {
        setTimeout(() => {
            let isError = false;
            setFildeError(false);
            form.getFieldsError().some((field) => {
                isError = isError || field.errors.length > 0;
            })
            setFildeError(isError || form.getFieldValue("oldPassword") == "");
        }, 100)
    }

    useEffect(() => {
        subscribeForError(REQUEST_CHANGE_PROFILE_PASSWORD, () => {
            form.resetFields();
        });
        return () => {
            unSubscribeForError(REQUEST_CHANGE_PROFILE_PASSWORD);
        }
    }, [])

    const onFinish = (data) => {
        changeProfilePassword({...data, email: user.email});
    }

    const changeInput = (e) => {
        setPassword({...passwords, [e.target.name]: e.target.value || ''});
    }

    const setIsValid = (data) => {
        setValidatePassInputs(prev => {
            let newState = [...prev];
            newState[data.index] = data.isValid;
            return newState;
        })
    }

    const onReset = () => {
        setIsValidForm(true);
        chackIsValid();
    }

    useEffect(() => {
        setIsValidForm(validatePassInputs[0] && validatePassInputs[1] && passwords.password === passwords.confirmPassword);
    }, [validatePassInputs, passwords])

    return (
        <>
            <h3 className="profile-title">
                <FormattedMessage id='change_password'/>
            </h3>

            <div className="validation-help">
                <span><FormattedMessage id='password_requirements'/></span>
                <ul>
                    <li><FormattedMessage id='minimum_8_characters_long'/></li>
                    <li><FormattedMessage id='at_least_one_lowercase_character'/></li>
                    <li><FormattedMessage id='at_least_one_number_symbol_or_whitespace_character'/></li>
                </ul>
            </div>
            
            <Form
                layout="vertical"
                form={form}
                name="user-form"
                onFinishFailed={() => chackIsValid()}
                onFinish={onFinish}
                onReset={onReset}
                >
                <Form.Item label={<FormattedMessage id='current_password'/>}
                            rules={[
                                {
                                    required: true,
                                    message: <FormattedMessage id='current_password_validator'/>
                                }
                            ]}
                            onBlur={() => chackIsValid()}
                            name="oldPassword">
                    <Input.Password autoComplete="off"
                                    onChange={() => chackIsValid()}
                                    onBlur={() => chackIsValid()}
                                    placeholder={intl.formatMessage({id: "type_current_password"})}/>
                </Form.Item>
                <ValidPasswordPopup open={isFocusOnPassword} inputText={passwords.password} index={0} onValid={setIsValid}>
                    <Form.Item label={<FormattedMessage id='new_password'/>}
                                rules={[
                                    {
                                        required: true,
                                        message: <FormattedMessage id='last_new_password'/>
                                    }
                                ]}
                                name="newPassword">
                        <Input.Password onChange={changeInput}
                                        onFocus={() => setIsFocusOnPassword(true)} 
                                        onBlur={() => setIsFocusOnPassword(false)}
                                        autoComplete="off"
                                        name="password"
                                        placeholder={intl.formatMessage({id: "type_new_password"})}/>
                    </Form.Item>
                </ValidPasswordPopup>

                <ValidPasswordPopup open={isFocusOnConfirmPassword} inputText={passwords.confirmPassword} index={0} onValid={setIsValid}>
                    <Form.Item label={<FormattedMessage id='retype_new_password'/>}
                                style={{marginBottom: 32}}
                                rules={[
                                    {
                                        required: true,
                                        message: <FormattedMessage id='retype_new_password_is_required'/>
                                    },
                                    // ({ getFieldValue }) => ({
                                    //     validator(_, value) {
                                    //     if (!value || getFieldValue('newPassword') === value) {
                                    //         return Promise.resolve();
                                    //     }
                                    //     return Promise.reject(new Error(intl.formatMessage({id: 'the_two_passwords_that_you_entered_do_not_match'})));
                                    //     },
                                    // })
                                ]}
                                name="retype_new_password">
                        <Input.Password onChange={changeInput}
                                        onFocus={() => setIsFocusOnConfirmPassword(true)}
                                        onBlur={() => setIsFocusOnConfirmPassword(false)}
                                        autoComplete="off"
                                        name="confirmPassword"
                                        placeholder={intl.formatMessage({id: "retype_new_password"})}/>
                    </Form.Item>
                </ValidPasswordPopup>
                
                <Form.Item className="MainModal--footer">
                    <button type="reset" className="miniBtn default" onClick={handleCancel}>
                        <FormattedMessage id='discard'/>
                    </button>
                    <Button type="primary"
                            htmlType="submit"
                            style={{height: 36}}
                            className={classname("miniBtn", {submit: fildeError || !isValidForm})}
                            loading={loading['_change_profile_password']}
                            disabled={fildeError || !isValidForm}
                    >
                        <FormattedMessage id='save'/>
                    </Button>
                    <div className="error_box">
                        <span className="block"><FormattedMessage id={"there_are_errors_on_the_form"}/>.</span>
                        <span className="block"><FormattedMessage id={"please_fix_them_before_continuing"}/>.</span>
                    </div>
                </Form.Item>
            </Form>
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        user: state.user?.profile
    }
}

const mapDispatchToProps = {
    changeProfilePassword
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(ChangeProfilePassword));