import React, {useEffect} from "react";
import {connect, Provider as ReduxProvider} from "react-redux";
import {
    Route,
    Redirect,
    useLocation
  } from "react-router-dom";

function PrivateRoute({ children, user, ...rest }) {

  const history = useLocation();

  useEffect(() => {
      document.querySelector(".site-layout").scrollTo(0, 0)
  },[history])

    return (
      <Route
        {...rest}
        render={({ location }) =>
        user ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location }
              }}
            />
          )
        }
      />
    );
  }


function mapStateToProps(state) {
  return {
      user: state.user
  };
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);