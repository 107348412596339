import initialStore from "../initialStore";
import {
    CHANGE_LOADING,
    CLEAR_APPS
} from "@actions/actionTypes";

export default function loadingReducer(state = initialStore.emptyObj, action) {
    switch (action.type) {
        case CHANGE_LOADING:
            return {...state, ...action.loading};
        case CLEAR_APPS: {
            let newState = Object.assign({}, state);
            delete newState['_apps'];
            return newState;
        }
            
        // case CLEAR_CLIENTS: {
        //     let newState = Object.assign({}, state);
        //     delete newState['_clents'];
        //     return newState;
        // }

        default:
            return state;
    }
}
