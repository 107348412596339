import React, {useState} from "react";
import {connect} from "react-redux";
import {Space, Dropdown, Table} from "antd";
import classnames from "classnames";
import HeaderTamplate from '../HeaderTamplate';
import {FormattedMessage, injectIntl} from "react-intl";
import arrow_up from "@assets/img/icon/arrow_up.svg";
import arrow_down from "@assets/img/icon/arrow_down.svg";

function EarningTable({earningTableStatistcs, intl, loading, ...resProps}) {
    
    const [selectedDuration, setSelectedDuration] = useState(0)

    const changeDuration = (i) => {
        // console.log(i)
        setSelectedDuration(i)
    }

    const getByStatistics = (text, differenceByPercent) => {
        return (
            <Space direction="horizontal" size={[12, 0]}>
                <span >{text}</span>
                <Space direction="horizontal"
                        size={[4, 0]}
                        className={classnames({isUp: differenceByPercent > 0, isDown: differenceByPercent < 0})}>
                    <span>{(differenceByPercent > 0? '+' : "-") + Math.abs(differenceByPercent)}</span>
                    <img src={differenceByPercent > 0? arrow_up : arrow_down}/>
                </Space>
            </Space>
        )
    }

    const columns = [
        {
            title: intl.formatMessage({id: "app"}),
            dataIndex: 'app',
            render: (text, record) => (
                <div className="app-img">
                    <img src={record.appImageUrl} />
                    <span>{record.appText}</span>
                </div>
            )
        },
        {
            title: intl.formatMessage({id: "profit"}),
            dataIndex: 'profit',
            render: (text, record) => getByStatistics(text, record.profitDifference)
        },
        {
            title: intl.formatMessage({id: "sessions"}),
            dataIndex: 'sessions',
            render: (text, record) => getByStatistics(text, record.sessionsDifference)
        },
        {
            title: intl.formatMessage({id: "avg_session"}),
            dataIndex: 'avgSession',
            render: (text, record) => getByStatistics(text, record.avgSessionDifference)
        },
        {
            title: intl.formatMessage({id: "bets"}),
            dataIndex: 'bets',
            render: (text, record) => getByStatistics(text, record.betsDifference)
        },
        {
            title: intl.formatMessage({id: "avg_bet"}),
            dataIndex: 'avgBet',
            render: (text, record) => getByStatistics(text, record.avgBetDifference)
        },
        {
            title: intl.formatMessage({id: "wins"}),
            dataIndex: 'wins',
            render: (text, record) => getByStatistics(text, record.winsDifference)
        },
        {
            title: intl.formatMessage({id: "refunds"}),
            dataIndex: 'refunds',
            render: (text, record) => getByStatistics(text, record.refundsDifference)
        },
        {
            title: intl.formatMessage({id: "loses"}),
            dataIndex: 'loses',
            className: 'noPaddingRight',
            render: (text, record) => getByStatistics(text, record.losesDifference)
        },
        {
            title: '',
            className: 'noPadding',
            render: () => ""
        }
    ]

    return (
        <HeaderTamplate className='earning-table-block'
            changeDuration={changeDuration}
            title="earning"
            selectedDuration={selectedDuration}
            lastUpdate={'Update 1 min. ago'}
            size={0}>
                <div className="mainTable">
                    <Table
                        dataSource={earningTableStatistcs}
                        columns={columns}
                        rowKey="appText"
                        pagination={false}
                        />
                </div>
        </HeaderTamplate>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading,
        earningTableStatistcs: state.earningTableStatistcs
    }
}

const mapDispatchToProps = {
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(EarningTable));