import React, {useEffect, useContext, useState} from "react";
import {connect} from "react-redux";
import {
    addApp,
    editApp
} from "@actions/appsActions";
import {
    REQUEST_ADD_APP,
    REQUEST_EDIT_APP
} from "@actions/actionTypes";

import {Modal, Form, Input, Menu, Radio, Dropdown, Tabs, Space, Button, Tooltip} from 'antd';
import {FormattedMessage, injectIntl} from "react-intl";
import classname from "classnames";
import {
    ResponseContext
} from "../../hooks/ResponseObserver";
import ImgUpload from "@components/ImgUpload/ImgUpload";
import { MinusOutlined, PlusOutlined, DeleteFilled} from '@ant-design/icons';
import MethodsDropDown from "@components/MethodsDropDown";




function AppControlPopup({handleCancel, show, intl, app, loading, addApp, editApp}) {
    const [form] = Form.useForm();
    const {subscribeForError, unSubscribeForError} = useContext(ResponseContext);
    const [tabMode, setTabMode] = useState("1");
    const [firstTabError, setFirstTabError] = useState(false)
    const [secondTabValid, setSecondTabValid] = useState(false)
    const [isFirstOpen, setIsFirstOpen] = useState(!app?.id)


    const handleModeChange = (e) => {
        const mode = e.target.value;
        if (mode === "2") {
            setIsFirstOpen(false)
        }
        setTabMode(mode);
    }

    useEffect( () => {
        if (isFirstOpen) {
            return
        }
        formValueChange();
    }, [tabMode])

    const formValueChange = () => {
        let firstTabValid = false;
        let secondTabValid = false;
        setFirstTabError(false);
        setSecondTabValid(false);
        form.getFieldsError().some((field) => {
            if (field.name.length >= 3) {
                secondTabValid = secondTabValid || field.errors.length > 0
            } else {
                firstTabValid = firstTabValid || field.errors.length > 0
            }
            
        })
        setFirstTabError(firstTabValid)
        setSecondTabValid(secondTabValid)
    }

    useEffect(() => {
        if (app.id) {
            subscribeForError(REQUEST_EDIT_APP, () => {
                handleCancel();
            }, app.id);
        } else {
            subscribeForError(REQUEST_ADD_APP, () => {
                handleCancel();
            });
        }
        form.setFieldsValue({isActive: false})
        return () => {
            unSubscribeForError(REQUEST_ADD_APP);
            unSubscribeForError(REQUEST_EDIT_APP);
        }
    }, [])

    useEffect(() => {
        if(app.id) {
            form.setFieldsValue(app)
        }
    }, [app])

    const onFinish = (data) => {
        if (show === "add") {
            addApp(data)
        } else {
            editApp({...data, id: app.id})
        }
    }

    return (
        <>
            {

                <Modal
                    title={show === "add" ? <FormattedMessage id='add_app'/> :
                        <FormattedMessage id='edit_app'/>}
                    centered
                    className={`formModal app-modal ${tabMode == 1? '' : 'hide-help-text'}`}
                    visible={show}
                    width={424}
                    onCancel={() => handleCancel()}
                    footer={false}>
                        <div className="tabMode centeredBox">
                            <Radio.Group onChange={handleModeChange} value={tabMode} className={classname({invalid: firstTabError || secondTabValid})}>
                                <Radio.Button className={classname("centeredBox", {invalid: firstTabError})} value="1" style={{minWidth: 186}}><FormattedMessage id={'details'}/></Radio.Button>
                                <Radio.Button className={classname("centeredBox", {invalid: secondTabValid})} value="2" style={{minWidth: 186}}><FormattedMessage id={'permission_s'}/></Radio.Button>
                            </Radio.Group>
                        </div>
                    <Form
                        layout="vertical"
                        form={form}
                        name="app-form"
                        onFinish={onFinish}
                        onFieldsChange={formValueChange}
                        scrollToFirstError
                        validateTrigger="onBlur"
                    >
                        <Tabs activeKey={tabMode}>
                            <Tabs.TabPane key="1" style={{padding: "0 24px 0 24px"}}>
                                <div className="app-img-block">
                                    <Form.Item label={<FormattedMessage id='app_name'/>}
                                               rules={[
                                                   {
                                                       required: true,
                                                       message: <FormattedMessage id='name_validator'/>
                                                   },
                                                   ({getFieldValue}) => ({
                                                       validator(_, value) {

                                                           if (value && (value.length > 255 || value.length < 3))
                                                           {
                                                               return Promise.reject(intl.formatMessage({id: `app_name_not_valid`}))

                                                           }
                                                           return Promise.resolve();
                                                       },
                                                   })
                                               ]}
                                               name={"name"}>
                                        <Input autoComplete="off" placeholder={intl.formatMessage({id: "type_app_name"})}/>
                                    </Form.Item>
                                </div>
                                <div className="app-img-block">
                                    <Form.Item name="imageUrl"
                                                label={false}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: <FormattedMessage id='icon_validator'/>
                                                    },
                                                ]}
                                                className="appImgUpload"
                                                >
                                        <ImgUpload icon={<PlusOutlined />} isOnlyImg={true} uploadText={<FormattedMessage id='upload_logo'/>}/>
                                    </Form.Item>
                                    <Form.Item name="isActive"
                                                label={false}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: <FormattedMessage id='enabled_validator'/>
                                                    },
                                                ]}
                                                >
                                        <Radio.Group>
                                            
                                                <Radio.Button className="centeredBox on-button" value={true}>
                                                    <FormattedMessage id={'on'}/>
                                                </Radio.Button>
                                                <Radio.Button className="centeredBox off-button" value={false}>
                                                    <FormattedMessage id={'off'}/>
                                                </Radio.Button>
                                            
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                                
                            </Tabs.TabPane>
                            <Tabs.TabPane key="2" className="permissions-tab">
                                <Form.List name="permissions">
                                    
                                    {(fields, { add, remove }) => (
                                    <>
                                        {fields.map(({ key, name, fieldKey: parentFieldKey, ...restField }, index) => (
                                            <div key={key} className="permissions-block">
                                                <Space className="inputs" style={{ display: 'flex', width: '100%'  }} align="baseline">
                                                    <Form.Item
                                                        {...restField}
                                                        label={<FormattedMessage id='permission_name'/>}
                                                        name={[name, 'name']}
                                                        fieldKey={[parentFieldKey, 'name']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({id: "missing_name"})
                                                            },
                                                            ({getFieldValue}) => ({
                                                                validator(_, value) {
                                                                if (value && (value.length < 3 || value.length > 256)) {
                                                                        return Promise.reject(intl.formatMessage({id: `name_is_not_valid`}));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            })
                                                        ]}
                                                        >
                                                        <Input placeholder={intl.formatMessage({id: "type_permission_name"})} />
                                                    </Form.Item>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'description']}
                                                        label={<FormattedMessage id='description'/>}
                                                        fieldKey={[parentFieldKey, 'description']}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: intl.formatMessage({id: "missing_description"})
                                                            },
                                                            ({getFieldValue}) => ({
                                                                validator(_, value) {
                                                                if (value && (value.length < 3 || value.length > 256)) {
                                                                        return Promise.reject(intl.formatMessage({id: `description_is_not_valid`}));
                                                                    }
                                                                    return Promise.resolve();
                                                                },
                                                            })
                                                        ]}
                                                        >
                                                        <Input placeholder={intl.formatMessage({id: "type_description"})} />
                                                    </Form.Item>
                                                    <Tooltip placement="right" title={<FormattedMessage id="delete_permission"/>}>
                                                        <DeleteFilled onClick={
                                                            () => {
                                                                remove(name);
                                                                requestAnimationFrame(() => formValueChange());
                                                            }}/>
                                                    </Tooltip>

                                                </Space>
                                                <div>
                                                    <span className="text-capitalize" style={{marginBottom: 8, display: 'block'}}>
                                                        <FormattedMessage id='endpoint_s'/>
                                                    </span>
                                                    <div className="column-reverse">
                                                        <Form.List name={[name, 'endpoints']}>
                                                            {(childFields, { add, remove }) => (
                                                                <>
                                                                    {childFields.map(({ key, name: childName, fieldKey: childFieldKey, ...restField }, index_fild) => (
                                                                        <Space key={key} className="inputs" style={{ display: 'flex', width: '100%'  }} align="baseline">
                                                                            <Form.Item
                                                                                {...restField}
                                                                                name={[childName, 'requestMethod']}
                                                                                fieldKey={[childFieldKey, 'requestMethod']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: intl.formatMessage({id: "missing_methods"})
                                                                                    }
                                                                                ]}
                                                                                >
                                                                                <MethodsDropDown />
                                                                            </Form.Item>
                                                                            
                                                                            <Form.Item
                                                                                {...restField}
                                                                                style={{width: 278}}
                                                                                name={[childName, 'url']}
                                                                                fieldKey={[childFieldKey, 'url']}
                                                                                rules={[
                                                                                    {
                                                                                        required: true,
                                                                                        message: intl.formatMessage({id: "url_is_required"})
                                                                                    },
                                                                                    // ({getFieldValue}) => ({
                                                                                    //     validator(_, value) {
                                                                                    //         const urlPattern = new RegExp( /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/gm)
                                                                                    //         if (value && !urlPattern.test(value) ) {
                                                                                    //             return Promise.reject(intl.formatMessage({id: `entered_value_is_not_url`}));
                                                                                    //         }
                                                                                    //         return Promise.resolve();
                                                                                    //     },
                                                                                    // })
                                                                                ]}
                                                                                >
                                                                                <Input placeholder={intl.formatMessage({id: "type_url"})} />
                                                                            </Form.Item>
                                                                            <div className={classname("icon")}
                                                                                onClick={() => {
                                                                                    if (childFields.length -1 > index_fild) {
                                                                                        remove(childName);
                                                                                        requestAnimationFrame(() => formValueChange());
                                                                                    } else {
                                                                                        add();
                                                                                        requestAnimationFrame(() => formValueChange());
                                                                                    }
                                                                                }}>
                                                                                <Tooltip placement="right"
                                                                                        title={childFields.length -1 > index_fild ?
                                                                                            <FormattedMessage id="delete_endPoint"/> :
                                                                                            <FormattedMessage id="add_endPoint"/>
                                                                                        }
                                                                                >
                                                                                    {childFields.length -1 > index_fild ? <MinusOutlined/>: <PlusOutlined/>}
                                                                                </Tooltip>
                                                                                </div>
                                                                        </Space>
                                                                    ))}
                                                                </>
                                                            )}
                                                        </Form.List>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                        <Form.Item className="add-button">
                                            <Tooltip placement="left" title={<FormattedMessage id="add_permission"/>}>
                                            <PlusOutlined
                                                onClick={() => {
                                                        add({endpoints: [{url: "", requestMethod: 0}]});
                                                        formValueChange()
                                                    }
                                                }/>
                                            </Tooltip>
                                        </Form.Item>
                                    </>
                                    )}
                                </Form.List>
                            </Tabs.TabPane>
                        </Tabs>
                        <Form.Item className="MainModal--footer">
                            <button type="reset" className="miniBtn default" onClick={handleCancel}>
                                <FormattedMessage id='cancel'/>
                            </button>
                            <Button type="primary"
                                    htmlType="submit"
                                    style={{height: 36}}
                                    className={classname("miniBtn", {submit: firstTabError || secondTabValid })}
                                    loading={loading._add_app || (app.id? loading['_edit_app' + app.id] : false)}
                                    disabled={firstTabError || secondTabValid}
                            >
                                <FormattedMessage id='save'/>
                            </Button>
                            <div className="error_box"><FormattedMessage id={"form_error"}/></div>
                        </Form.Item>
                    </Form>
                    <span className="help-text">
                        <FormattedMessage id="indicates_a_required_field" />
                    </span>
                </Modal>
            }
        </>
    )
}

function mapStateToProps(state) {
    return {
        loading: state.loading
    }
}

const mapDispatchToProps = {
    addApp,
    editApp
}

export default injectIntl(connect(mapStateToProps, mapDispatchToProps)(AppControlPopup));