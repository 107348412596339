import React, {useEffect} from "react";
import {MethodsArr} from "../../constants/Enums";
import {Dropdown, Button, Menu} from "antd"; 

function methodsDropDownItems (selectMethod) {

    const handleMenuClick = (selected) => {
        selectMethod(selected)
    }

    return (
            <Menu onClick={handleMenuClick} style={{width: 124}} className="methods-drop-down">
                {
                    MethodsArr.map((method, index) => (
                        <Menu.Item key={index}  rel="noopener noreferrer">
                        {method}
                        </Menu.Item>
                    ))
                }
            </Menu>
        )
    }

function MethodsDropDown({value = 0, onChange}) {
    const selectMethod = (selected) => {
        onChange(selected.key);
    }
    useEffect(() => {
        // console.log(value)
        onChange(+value);
    }, [value])

    return (
        <Dropdown getPopupContainer={_ => document.querySelector(".ant-modal-content")}
            overlay={() => methodsDropDownItems(selectMethod)}
            placement="bottomRight"
            trigger={["click"]}
            >
            <Button style={{width: 74}}>{MethodsArr[value]}</Button>
        </Dropdown>
    )
}

export default MethodsDropDown;