import React, { useState, useEffect } from "react";
import {connect} from "react-redux";
import { Popover} from 'antd';
import {FormattedMessage} from "react-intl";
import classnames from "classnames";
import {Re8Characers, ReLowerCase, ReUpperCase, ReNumber} from "../../utils/CommonUtils";
import "./style.sass";

function ValidPasswordPopup(props) {

    const [opened, setOpened] = useState(false);
    const [is8Characers, setIs8Characers] = useState();
    const [isLowerCase, setIsLowerCase] = useState();
    const [isUpperCase, setIsUpperCase] = useState();
    const [isNumber, setIsNumber] = useState();

    useEffect(() => {
        if(opened || props.open) {
            setIs8Characers(Re8Characers.test(props.inputText));
            setIsLowerCase(ReLowerCase.test(props.inputText));
            setIsUpperCase(ReUpperCase.test(props.inputText));
            setIsNumber(ReNumber.test(props.inputText));
        }
    })

    useEffect(() => {
        if (is8Characers != undefined || isLowerCase != undefined || isUpperCase != undefined || isNumber != undefined) {
            props.onValid({index: props.index, isValid: is8Characers && isLowerCase && isUpperCase && isNumber});
        }
    }, [is8Characers, isLowerCase, isUpperCase, isNumber])
   
    const hoverContent = <div>
                            <span className={classnames({invalid: !is8Characers})}>
                                <i className={ classnames('fi ', is8Characers? 'fi-rr-check' : 'fi-rr-cross')}/>
                                <FormattedMessage id='at_least_8_characters_in_length' />
                            </span>
                            <span className={classnames({invalid: !isLowerCase})}>
                                <i className={ classnames('fi ', isLowerCase? 'fi-rr-check' : 'fi-rr-cross')}/>
                                <FormattedMessage id='lower_case_letters_a_z' />
                            </span>
                            <span className={classnames({invalid: !isUpperCase})}>
                                <i className={ classnames('fi ', isUpperCase? 'fi-rr-check' : 'fi-rr-cross')}/>
                                <FormattedMessage id='upper_case_letters_a_z' />
                            </span>
                            <span className={classnames({invalid: !isNumber})}>
                                <i className={ classnames('fi ', isNumber? 'fi-rr-check' : 'fi-rr-cross')}/>
                                <FormattedMessage id='number_0_9' />
                            </span>
                        </div>

    return (
        <Popover
            style={{ width: 500 }}
            content={hoverContent}
            placement="right"
            overlayClassName="valid-pass"
            title={<FormattedMessage id='strong_password_policy' />}
            visible={opened || props.open}
        >
            {props.children }
        </Popover>
    )
}

function mapStateToProps(state) {
    return {
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(ValidPasswordPopup);